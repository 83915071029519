import React from 'react';

import { Search, DropdownMenu, CheckboxMenuItem } from 'components';

const OrderFilters = () => (
  <div className="search">
    <Search onChange={() => console.log('here')} placeholder="Buscar" value="" />
    <DropdownMenu
      heading={
        <div className="c-filter">
          <i className="icon-filter"></i>
        </div>
      }
      items={[
        <CheckboxMenuItem key="blocked" label={'Bloqueado'} on handleCheckboxChange={() => console.log('e')} />,
        <CheckboxMenuItem key="end" label={'Finalizado'} handleCheckboxChange={() => console.log('e')} />,
        <CheckboxMenuItem key="progress" label={'En Proceso'} handleCheckboxChange={() => console.log('e')} />
      ]}
      withIcon={false}
    />
  </div>
);

export default OrderFilters;
