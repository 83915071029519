import React, { useEffect, useState } from 'react';
import { useTable, useRowSelect } from 'react-table';

import './style.scss';

import wspIcon from 'images/imagen-wsp.png';
import emailIcon from 'images/imagen-email.png';
import TableSpinner from 'components/atoms/TableSpinner';

function Table({ columns, data, fetchMoreData, rememberSelection }) {
  const dataTableRef = React.createRef();
  const scrollContainerRef = React.createRef();

  const [showTableSpinner, setShowTableSpinner] = useState(false);
  // TODO: OPTIONAL Convert this into an hook https://upmostly.com/tutorials/build-an-infinite-scroll-component-in-react-using-react-hooks
  useEffect(() => {
    const currentScrollContainer = scrollContainerRef.current;

    const handleScroll = () => {
      if (
        scrollContainerRef.current &&
        scrollContainerRef.current.scrollHeight - scrollContainerRef.current.scrollTop !==
          scrollContainerRef.current.clientHeight
      )
        return;

      fetchMoreData();
      setShowTableSpinner(true);
    };

    currentScrollContainer.addEventListener('scroll', handleScroll);
    return () => currentScrollContainer.removeEventListener('scroll', handleScroll);
  }, [scrollContainerRef, dataTableRef, fetchMoreData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds }
  } = useTable(
    {
      columns,
      data,
      autoResetSelectedRows: !rememberSelection
    },
    useRowSelect
  );

  const haveSelectedElements = !!Object.keys(selectedRowIds).length;
  const haveOneSelectedElement = Object.keys(selectedRowIds).length === 1;

  function getCurrentPhoneNumber() {
    return [...selectedFlatRows.map(d => d.original.phone)];
  }

  function getEmails() {
    return [...selectedFlatRows.map(d => d.original.email)];
  }

  return (
    <>
      <div className="data-table" ref={dataTableRef}>
        <div className="scroll-container" ref={scrollContainerRef}>
          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()} className="cell cell-header">
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="cell">
                    {row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps()} className="cell">
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {showTableSpinner && <TableSpinner />}
        </div>
      </div>
      <div className="box-send active">
        {haveSelectedElements && haveOneSelectedElement && (
          <a
            href={`//api.whatsapp.com/send?phone=${getCurrentPhoneNumber()}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="wsp" src={wspIcon} />
          </a>
        )}
        {haveSelectedElements && (
          <a href={`//mail.google.com/mail/?view=cm&fs=1&to=${getEmails()}`} target="_blank" rel="noopener noreferrer">
            <img alt="email" src={emailIcon} />
          </a>
        )}
      </div>
    </>
  );
}

export default Table;
