import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import Amplify from '@aws-amplify/core';
import awsconfig from './aws-exports';

import configureStore from 'store/configure';

import { basename } from 'config';
import api from 'services/api';
import App from 'components/App';

import { isProd, featureFlags } from 'config';

import { ToastContainer } from 'react-toastify';

import 'styles/global.scss';

import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';

if (isProd) {
  Sentry.init({ dsn: 'https://49d575f46fde46a6a09223ac0e4ac575@sentry.io/1510773' });
}

const loginWithCognito = featureFlags.find(ff => ff.name === 'loginWithCognito');

if (loginWithCognito && loginWithCognito.active === true) {
  console.log('Using AWS Cognito');
  Amplify.configure(awsconfig);
}

const store = configureStore({}, { api: api.create() });

const renderApp = () => (
  <Provider store={store}>
    <BrowserRouter basename={basename}>
      <App />
    </BrowserRouter>
    <ToastContainer position="bottom-right" autoClose={5000} />
  </Provider>
);

render(renderApp(), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
