import React from 'react';
import { useSelector } from 'react-redux';
import { pending } from 'redux-saga-thunk';

import './style.scss';

const Spinner = ({ forceShow = false }) => {
  const showSpinner = useSelector(state => pending(state, ['customersListRead']));

  return (
    (showSpinner || forceShow) && (
      <div className="c-spinner">
        <div className="c-spinner--circle">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  );
};
export default Spinner;
