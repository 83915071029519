import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { NotFoundPage } from 'components';

import TechnicianList from 'components/organisms/Technician/TechnicianList';
// import CustomerForm from 'components/organisms/Customer/CustomerForm';

const CustomersPageRoutes = () => {
  return (
    <Switch>
      <Route exact path="/technicians" render={() => <TechnicianList />} />
      {/* <Route path="/technicians/new" render={({ ...props }) => <CustomerForm {...props} />} /> */}
      {/* <Route
        exact
        path="/customers/:id/edit"
        render={({ match, ...props }) => <CustomerForm editMode={true} customerId={match.params.id} {...props} />}
      /> */}
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default CustomersPageRoutes;
