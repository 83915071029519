import React from 'react';
import classNames from 'classnames';

import { NavLink, Link } from 'react-router-dom';
import { Button } from 'components';
import { format } from 'date-fns';

import './style.scss';

const OrderList = ({ orderList = [] }) => (
  <>
    <Link className="new-order" to={{ pathname: '/orders' }}>
      <Button type="button" buttonType="primary">
        Nueva Orden
      </Button>
    </Link>

    {orderList.length !== 0 ? (
      orderList.map((item, index) => (
        <NavLink
          key={`boxOrders-${index}`}
          activeClassName="active"
          className="box-orders"
          to={`/orders/${item.orderNumber}`}
        >
          <div className="box-orders--item1">
            <span className="nro">{item.orderNumber}</span>
            <div
              className={classNames('state', {
                green: item.currentStatus === 'end',
                red: item.currentStatus === 'blocked',
                blue: item.currentStatus === 'new'
              })}
            >
              <span className="c-dot"></span>
              <span>{item.currentStatus === 'new' ? 'En proceso' : 'Bloqueado'}</span>
            </div>
          </div>
          <div className="box-orders--item2">
            <div className="date-client">
              <span className="name">{item.fullName}</span>
              <span className="date">{format(new Date(item.createdAt), 'dd/MM/yyyy')}</span>
            </div>
            <div className="options">.</div>
          </div>
        </NavLink>
      ))
    ) : (
      <div className="box-orders--empty h-color--gray-text">
        <p className="h-fz--20">¡Oops!</p>
        <p className="h-fz--14">No se encontraron ordenes</p>
      </div>
    )}
  </>
);

export default OrderList;
