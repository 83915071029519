import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from 'components';

import './style.scss';

function CheckboxMenuItem({ label, isSelected, handleCheckboxChange }) {
  return (
    <div className="checkbox-menu-item">
      <label className="h-flex--center-v">
        <Checkbox name="click" value="some" checked={isSelected} onChange={handleCheckboxChange} />
        <span>{label}</span>
      </label>
    </div>
  );
}

CheckboxMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  handleCheckboxChange: PropTypes.func.isRequired
};

CheckboxMenuItem.defaultProps = {
  isSelected: false
};

export default CheckboxMenuItem;
