import Auth from '@aws-amplify/auth';
import { featureFlags } from 'config';

const loginWithCognito = featureFlags.find(ff => ff.name === 'loginWithCognito');

const isAuthenticated = () => {
  return !!getCurrentUser().attributes;
};

const setCurrentUser = user => (window.localStorage.currentUser = JSON.stringify(user));

const getCurrentUser = () => {
  if (window.localStorage.currentUser) {
    let user = JSON.parse(window.localStorage.currentUser);
    return user ? user : {};
  }
  return {};
};

const getCurrentUserGroups = () => getCurrentUser().signInUserSession.accessToken.payload['cognito:groups'];

const isDefaultUser = () => getCurrentUserGroups()?.includes('default-group');
const isTechnicianUser = () => getCurrentUserGroups()?.includes('technician-group');
const isAdminUser = () => getCurrentUserGroups()?.includes('admin-group');

const getFullName = () => {
  const currentUser = getCurrentUser();
  const firstName = currentUser.attributes['custom:first_name'];
  const lastName = currentUser.attributes['custom:last_name'];

  return `${firstName} ${lastName}`;
};

const getSub = () => {
  return getCurrentUser().attributes.sub;
};

const saveCurrentUser = async () => {
  if (loginWithCognito && loginWithCognito.active === true) {
    const currentUser = await Auth.currentAuthenticatedUser();
    setCurrentUser(currentUser);
  } else {
    setCurrentUser({ user: 'beta', attributes: { 'custom:first_name': "beta", "custom:last_name": "user", role: "sample" } });
  }
}

const signIn = async (username, password) => {
  if (loginWithCognito && loginWithCognito.active === true) {
    return Auth.signIn(username, password);
  } else {
    // return Promise.resolve({ challengeName: 'NEW_PASSWORD_REQUIRED' })
    if (username === 'beta' && password === '**supers3cr3t**') {
      return Promise.resolve({ user: "beta" })
    } else {
      return Promise.reject(new Error('Wrong credentials'))
    }
  }
}

const signOut = () => {
  setCurrentUser({});
};

export default {
  isAuthenticated,
  getSub,
  saveCurrentUser,
  setCurrentUser,
  getCurrentUser,
  getCurrentUserGroups,
  isDefaultUser,
  isTechnicianUser,
  isAdminUser,
  getFullName,
  signIn,
  signOut
};
