import React, { useEffect, useCallback } from 'react';
import * as Yup from 'yup';

import { useSelector, useDispatch } from 'react-redux';
import { resourceDetailReadRequest, resourceCreateRequest, resourceUpdateRequest } from 'store/actions';
import { fromEntities } from 'store/selectors';

import { withFormik } from 'formik';

import { Title, Box, Breadcrumbs } from 'components';
import CustomerForm from './component';

import { toast } from 'react-toastify';

const clean = require('obj-clean');

const CustomerFormContainer = ({ editMode = false, customerId, ...formikProps }) => {
  const dispatch = useDispatch();
  const currentCustomer = useSelector(state => fromEntities.getDetail(state, 'customers', customerId));

  const triggerSaveCustomer = payload => {
    const cleanedCustomer = clean(payload);
    return editMode
      ? dispatch(resourceUpdateRequest('customers', customerId, { customer: cleanedCustomer }))
      : dispatch(resourceCreateRequest('customers', { customer: cleanedCustomer }));
  };

  const memoizedFetchCustomer = useCallback(
    customerId => {
      dispatch(resourceDetailReadRequest('customers', customerId));
    },
    [dispatch]
  );

  const getProperTitle = () => (editMode ? <Title>Editar cliente</Title> : <Title>Agregar cliente</Title>);
  const getCurrentLinks = () =>
    editMode
      ? [
          {
            text: 'Listado de clientes',
            url: '/customers'
          },
          {
            text: 'Editar cliente',
            url: '/customers/new'
          }
        ]
      : [
          {
            text: 'Listado de clientes',
            url: '/customers'
          },
          {
            text: 'Agregar cliente',
            url: '/customers/new'
          }
        ];

  const getCancelButtonText = () => (editMode ? 'Regresar' : 'Cancelar');
  const getSubmitButtonText = () => (editMode ? 'Guardar' : 'Agregar');

  useEffect(() => {
    if (editMode) {
      memoizedFetchCustomer(customerId);
    }
  }, [memoizedFetchCustomer, customerId, editMode]);

  return (
    <>
      <Breadcrumbs links={getCurrentLinks()} />
      <Box title={getProperTitle()}>
        <EnhancedCustomerForm
          currentCustomer={currentCustomer}
          cancelButtonText={getCancelButtonText()}
          submitButtonText={getSubmitButtonText()}
          submitAction={triggerSaveCustomer}
          {...formikProps}
        />
      </Box>
    </>
  );
};

const validationSchema = Yup.object().shape({
  category: Yup.string().required('Este campo es obligatorio.'),
  firstName: Yup.string().required('Este campo es obligatorio.'),
  lastName: Yup.string().required('Este campo es obligatorio.'),
  address: Yup.object().shape({
    description: Yup.string().required('Este campo es obligatorio.'),
    district: Yup.string().required('Este campo es obligatorio.')
  }),
  products: Yup.array().of(
    Yup.object().shape({
      artifact: Yup.string().required('Este campo es obligatorio.'),
      brand: Yup.string().required('Este campo es obligatorio.'),
      model: Yup.string().required('Este campo es obligatorio.'),
      distributor: Yup.string().required('Este campo es obligatorio.')
    })
  )
});

const initialValues = {
  category: '',
  firstName: '',
  lastName: '',
  documentType: '',
  documentNumber: '',
  phone: '',
  address: {
    description: '',
    district: '',
    gmapsUrl: '',
    locationReferences: '',
    latitude: '',
    longitude: ''
  },
  email: '',
  products: [],
  isReported: ''
};

const handleSubmit = async (values, { setSubmitting, props }) => {
  try {
    await props.submitAction({ ...values, isReported: !!values.isReported });
    toast.success('Se guardaron los cambios exitosamente');
    setSubmitting(false);
    props.history.push('/customers');
  } catch (e) {
    const responseJson = await e.response.json();
    switch (true) {
      case responseJson.data.includes('documentNumber'):
        toast.error('El número de documento especificado esta siendo usado por otro usuario');
        break;
      case responseJson.data.includes('email'):
        toast.error('El email especificado esta siendo usado por otro usuario');
        break;
      default:
        toast.error('Error al intentar guardar al cliente');
        break;
    }
    console.error('Error:', e);
  }
};

const EnhancedCustomerForm = withFormik({
  mapPropsToValues: ({ currentCustomer }) => currentCustomer || initialValues,
  validationSchema: validationSchema,
  handleSubmit: handleSubmit,
  enableReinitialize: true
})(CustomerForm);

export default CustomerFormContainer;
