import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import { NotFoundPage, Box, Title } from 'components';
import OrderFilters from 'components/organisms/Order/OrderFilters';
import OrderWizard from 'components/organisms/Order/OrderWizard';
import OrderList from 'components/organisms/Order/OrderList';

const OrdersPageRoutes = () => {
  const ordersListRef = React.createRef();
  const ordersBodyRef = React.createRef();

  useEffect(() => {
    ordersListRef.current.style.maxHeight = ordersBodyRef.current.clientHeight - 55 + 'px';
  }, [ordersBodyRef, ordersListRef]);

  return (
    <Box title={<Title>Listado de ordenes</Title>}>
      <div className="box-body__orders">
        <div className="box-body__orders--left">
          <OrderFilters />
          {/* <div className="list-orders" ref={ordersListRef}> */}
          {/* TODO: Refactor? */}
          <div className="list-orders" ref={ordersListRef}>
            <OrderList />
          </div>
        </div>
        <div className="box-body__orders--right" ref={ordersBodyRef}>
          <Switch>
            <Route exact path="/orders" render={() => <OrderWizard />} />
            <Route exact path="/orders/:orderId" render={() => <OrderWizard />} />
            <Route component={NotFoundPage} />
          </Switch>
        </div>
      </div>
    </Box>
  );
};

export default OrdersPageRoutes;
