import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import NewPasswordForm from './component';

import { Modal } from 'components';

import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { modalHide } from 'store/actions';

import Auth from '@aws-amplify/auth';

const NewPasswordModalContainer = props => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.modal.data);
  const triggerCurrentModalHide = () => dispatch(modalHide('newPassword'));

  return (
    <Modal title="Bienvenido" name="newPassword" closeable>
      <EnhancedNewPasswordForm {...{ triggerCurrentModalHide, currentUser }} {...props} />
    </Modal>
  );
};

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .max(30, 'Contraseña muy larga.')
    .required('Este campo es obligatorio.'),

  newPasswordConfirmation: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Contraseñas no coinciden.')
    .required('Este campo es obligatorio.')
});

const initialValues = { code: '', newPassword: '', newPasswordConfirmation: '' };

const handleSubmit = async (values, { setSubmitting, props }) => {
  try {
    await Auth.completeNewPassword(props.currentUser, values.newPassword);
    props.triggerCurrentModalHide();
    toast.success('Contraseña cambianda correctamente, Ingrese de nuevo.');
    setSubmitting(false);
  } catch (e) {
    toast.error('Verifique que su nombre de usuario sea el correcto.');
    values.username = '';
  }
};

const EnhancedNewPasswordForm = withFormik({
  mapPropsToValues: () => initialValues,
  validationSchema: validationSchema,
  handleSubmit: handleSubmit
})(NewPasswordForm);

export default NewPasswordModalContainer;
