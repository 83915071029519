import React from 'react';

import './style.scss';

const Radio = ({ label, onChange, value, id }) => {
  return (
    <div className="c-radio">
      <input type="radio" name="sample" value={value} onChange={onChange} id={id} />
      <label htmlFor={id}>
        <span></span>
        {label}
      </label>
    </div>
  );
};

export default Radio;
