import React from 'react';

import { getIn } from 'formik';

import { TextField, Select, Checkbox } from 'components';

import { CUSTOMER_CATEGORIES, DISTRICTS, DOCUMENT_TYPE } from 'services/mockData';

const CustomerDetails = ({ handleChange, handleBlur, touched, errors, values, onlyCustomer }) => (
  <div className="data-client" style={{ padding: onlyCustomer && 0 }}>
    <div className="row">
      <div className="col-10">
        <div className="title">Datos cliente</div>
      </div>
      <div className="col-2 h-text-align--right">
        {onlyCustomer && (
          <button type="submit" className="c-btn--icon">
            <i className="icon-save"></i>
          </button>
        )}
      </div>
    </div>
    <div className="form">
      <Select
        id="category"
        name="category"
        label="Tipo de cliente"
        placeholder="Seleccione"
        onChange={handleChange}
        onBlur={handleBlur}
        required={true}
        selectedOption={values.category}
        hasError={errors.category && touched.category}
        errorMessage={errors.category}
        options={CUSTOMER_CATEGORIES}
        disabled={!!values.isReported}
      />
      <TextField
        id="firstName"
        name="firstName"
        label="Nombre"
        onChange={handleChange}
        onBlur={handleBlur}
        required={true}
        value={values.firstName}
        hasError={errors.firstName && touched.firstName}
        errorMessage={errors.firstName}
        disabled={!!values.isReported}
      />
      <TextField
        id="lastName"
        name="lastName"
        label="Apellido"
        onChange={handleChange}
        onBlur={handleBlur}
        required={true}
        value={values.lastName}
        hasError={errors.lastName && touched.lastName}
        errorMessage={errors.lastName}
        disabled={!!values.isReported}
      />
    </div>
    <div className="form">
      <Select
        id="documentType"
        name="documentType"
        label="Tipo de documento"
        placeholder="Seleccione"
        onChange={handleChange}
        onBlur={handleBlur}
        required={true}
        selectedOption={values.documentType}
        hasError={errors.documentType && touched.documentType}
        errorMessage={errors.documentType}
        options={DOCUMENT_TYPE}
        disabled={!!values.isReported}
      />
      <TextField
        id="documentNumber"
        name="documentNumber"
        label="Nro documento"
        onChange={handleChange}
        onBlur={handleBlur}
        required={true}
        value={values.documentNumber}
        hasError={errors.documentNumber && touched.documentNumber}
        errorMessage={errors.documentNumber}
        disabled={!!values.isReported || !values.documentType}
      />
    </div>
    <div className="form">
      <Select
        id="address.district"
        name="address.district"
        placeholder="Seleccione"
        label="Distrito"
        onChange={handleChange}
        onBlur={handleBlur}
        required={true}
        selectedOption={values.address ? values.address.district : ''}
        hasError={getIn(errors, 'address.district') && getIn(touched, 'address.district')}
        errorMessage={getIn(errors, 'address.district')}
        disabled={!!values.isReported}
        options={DISTRICTS}
      />
      <TextField
        id="address.description"
        name="address.description"
        label="Direccion"
        onClick={() => alert(1)}
        onChange={handleChange}
        onBlur={handleBlur}
        required={true}
        value={values.address ? values.address.description : ''}
        hasError={getIn(errors, 'address.description') && getIn(touched, 'address.description')}
        errorMessage={getIn(errors, 'address.description')}
        customIcon="icon-location"
        disabled={!!values.isReported}
      />
      <TextField
        id="address.locationReferences"
        name="address.locationReferences"
        label="Referencia"
        onChange={handleChange}
        onBlur={handleBlur}
        required={true}
        value={values.address ? values.address.locationReferences : ''}
        hasError={getIn(errors, 'address.locationReferences') && getIn(touched, 'address.locationReferences')}
        errorMessage={getIn(errors, 'address.locationReferences')}
        disabled={!!values.isReported}
      />
    </div>
    <div className="form">
      <TextField
        id="email"
        name="email"
        label="Correo"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        hasError={errors.email && touched.email}
        errorMessage={errors.email}
        disabled={!!values.isReported}
      />
      <TextField
        id="phone"
        name="phone"
        label="Telefono"
        onChange={handleChange}
        onBlur={handleBlur}
        required={true}
        value={values.phone}
        hasError={errors.phone && touched.phone}
        errorMessage={errors.phone}
        disabled={!!values.isReported}
      />
      {values._id && (
        <Checkbox
          id="isReported"
          name="isReported"
          label="Cliente reportado"
          checked={!!values.isReported}
          value={!!values.isReported}
          onChange={handleChange}
          hasError={errors.isReported && touched.isReported}
          errorMessage={errors.isReported}
        />
      )}
    </div>
  </div>
);

export default CustomerDetails;
