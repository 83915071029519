/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const Checkbox = ({
  errorMessage,
  disabled,
  hasError,
  name,
  value,
  onChange,
  label,
  className,
  indeterminate,
  ...attributes
}) => (
  <div className={classNames('c-checkbox', { error: hasError }, className)}>
    <label>
      <input
        className="filled-in"
        name={name}
        type="checkbox"
        {...attributes}
        disabled={disabled}
        onChange={onChange}
      />
      <span className={classNames('placeholder', { disabled })}>{label}</span>
    </label>
    {hasError && <span className="label-error">{errorMessage}</span>}
  </div>
);

Checkbox.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

Checkbox.defaultProps = {
  attributes: [],
  className: '',
  disabled: false,
  errorMessage: '',
  hasError: false,
  label: ''
};

export default Checkbox;
