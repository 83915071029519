import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DebounceInput } from 'react-debounce-input';

import './style.scss';

const Search = ({ placeholder, onChange, className, value, disabled }) => {
  return (
    <div className={classNames('c-search', className)}>
      <div className="c-search--icon">
        <i className="icon-search" />
      </div>
      <DebounceInput
        className="c-search--input"
        placeholder={placeholder}
        type="search"
        minLength={3}
        debounceTimeout={1000}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string
};

Search.defaultProps = {
  className: '',
  disabled: false,
  placeholder: 'Buscar...'
};

export default Search;
