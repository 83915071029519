import React, { useEffect, useCallback } from 'react';
import * as Yup from 'yup';

import Session from 'services/session';

import { useSelector, useDispatch } from 'react-redux';
import { fromEntities, fromResource } from 'store/selectors';
import {
  resourceListReadRequest,
  resourceCreateRequest,
  changeOrderCustomerSearchCriteria,
  selectOrderCustomer,
  selectOrderCustomerProduct,
  showOrderCustomerForm,
  showOrderProductForm,
  cleanSelectedOrderCustomer
} from 'store/actions';

import StepOne from './component';

const customerInitialValues = {
  category: '',
  firstName: '',
  lastName: '',
  documentType: '',
  documentNumber: '',
  phone: '',
  address: {
    description: '',
    district: '',
    gmapsUrl: '',
    locationReferences: '',
    latitude: '',
    longitude: ''
  },
  email: '',
  products: [],
  isReported: ''
};

const productInitialValues = {
  brand: ''
};

const customerValidationSchema = Yup.object().shape({
  category: Yup.string().required('Este campo es obligatorio.'),
  firstName: Yup.string().required('Este campo es obligatorio.'),
  lastName: Yup.string().required('Este campo es obligatorio.'),
  address: Yup.object().shape({
    description: Yup.string().required('Este campo es obligatorio.'),
    district: Yup.string().required('Este campo es obligatorio.')
  }),
  products: Yup.array().of(
    Yup.object().shape({
      artifact: Yup.string().required('Este campo es obligatorio.'),
      brand: Yup.string().required('Este campo es obligatorio.'),
      model: Yup.string().required('Este campo es obligatorio.'),
      distributor: Yup.string().required('Este campo es obligatorio.')
    })
  )
});

const StepOneContainer = ({ nextStep }) => {
  const dispatch = useDispatch();
  const orderCustomerSearchCriteria = useSelector(state => state.dashboard.order.customerSearchCriteria);
  const orderIsCustomerFormVisible = useSelector(state => state.dashboard.order.isCustomerFormVisible);
  const orderIsProductFormVisible = useSelector(state => state.dashboard.order.isProductFormVisible);

  const selectedOrderCustomer = useSelector(state => state.dashboard.order.selectedOrderCustomer);
  const selectedOrderCustomerProductList = useSelector(state => state.dashboard.order.selectedOrderCustomerProductList);
  const selectedOrderCustomerProduct = useSelector(state => state.dashboard.order.selectedOrderCustomerProduct);

  const customerList = useSelector(state =>
    fromEntities.getList(state, 'customers', fromResource.getList(state, 'customers'))
  );

  const triggerChangeOrderCustomerSearchCriteria = payload => dispatch(changeOrderCustomerSearchCriteria(payload));
  const triggerShowOrderCustomerForm = () => dispatch(showOrderCustomerForm());
  const triggerShowOrderProductForm = () => dispatch(showOrderProductForm());
  const triggerCleanSelectedOrderCustomer = () => dispatch(cleanSelectedOrderCustomer());
  const triggerCreateCustomer = payload => {
    const clean = require('obj-clean');
    const cleanedCustomer = clean(payload);
    dispatch(resourceCreateRequest('customers', cleanedCustomer));
  };

  const triggerSelectOrderCustomer = payload => dispatch(selectOrderCustomer(payload));
  const triggerSelectOrderCustomerProduct = payload => dispatch(selectOrderCustomerProduct(payload));

  const fetchCustomers = useCallback(() => {
    const clean = require('obj-clean');
    dispatch(resourceListReadRequest('customers', clean({ q: orderCustomerSearchCriteria })));
  }, [dispatch, orderCustomerSearchCriteria]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  const triggerNextStep = () => {
    const clean = require('obj-clean');
    const payload = {
      customerId: selectedOrderCustomer._id,
      productId: selectedOrderCustomerProduct,
      cognitoId: Session.getSub()
    };

    const cleanedOrder = clean(payload);
    dispatch(resourceCreateRequest('orders', { order: cleanedOrder }));
    nextStep();
  };

  const highlightFullName = (value, orderCustomerSearchCriteria) => {
    const firstIndex = value.toLowerCase().search(orderCustomerSearchCriteria.toLowerCase());
    return firstIndex === -1 || !orderCustomerSearchCriteria.length
      ? value
      : value.substring(0, firstIndex) +
          '<b>' +
          value.substring(firstIndex, orderCustomerSearchCriteria.length) +
          '</b>' +
          value.substring(orderCustomerSearchCriteria.length);
  };

  return (
    <StepOne
      {...{
        triggerNextStep,
        orderCustomerSearchCriteria,
        orderIsCustomerFormVisible,
        orderIsProductFormVisible,
        triggerChangeOrderCustomerSearchCriteria,
        triggerShowOrderCustomerForm,
        triggerShowOrderProductForm,
        triggerSelectOrderCustomer,
        triggerSelectOrderCustomerProduct,
        triggerCleanSelectedOrderCustomer,
        selectedOrderCustomer,
        selectedOrderCustomerProduct,
        selectedOrderCustomerProductList,
        customerList,
        customerInitialValues,
        productInitialValues,
        triggerCreateCustomer,
        customerValidationSchema,
        highlightFullName
      }}
    />
  );
};

// const StepOne = ({
//   isCustomerFormVisible,
//   selectedCustomer,
//   triggerShowCustomerForm,
//   triggerCreateCustomer,
//   isProductFormVisible
// }) => (
//   <>
//     <div className="container">
//       <div className="step-orders step-orders--one">
//         <div className="h-pt--3 h-mb--3 h-align-items--center">
//           {!isCustomerFormVisible && (
//             <div className="row">
//               <div className="col-12">
//                 <p className="h-fz--14 h-fw--bold h-mb--2 h-color--gray-text">Cliente</p>
//               </div>
//               <div className="col">
//                 {!selectedCustomer && (
//                   <DropdownMenu
//                     withIcon={false}
//                     heading={
//                       <Search
//                         placeholder="Buscar cliente"
//                         onChange={e => e.target.value && triggerChangeCustomerSearchCriteria(e.target.value)}
//                         value={customerSearchCriteria}
//                       />
//                     }
//                     items={customersList.map(customer => (
//                       <ClickableMenuItem onClick={() => triggerSelectCustomer(customer)}>
//                         <span className="name" dangerouslySetInnerHTML={{ __html: customer.fullNameForHTML }} />
//                         <span className="document-number">{customer.documentNumber}</span>
//                       </ClickableMenuItem>
//                     ))}
//                   />
//                 )}
//                 {selectedCustomer && <Search disabled={true} placeholder="Buscar cliente" value={fullName} />}
//               </div>
//               <div className="col-3">
//                 <div className="search-btn">
//                   {!selectedCustomer && (
//                     <Button className="h-mr--1" type="button" buttonType="secondary" onClick={triggerShowCustomerForm}>
//                       Nuevo
//                     </Button>
//                   )}

//                   {selectedCustomer && (
//                     <Button className="h-mr--1" type="button" buttonType="secondary" onClick={triggerCleanSelections}>
//                       Borrar
//                     </Button>
//                   )}
//                 </div>
//               </div>
//             </div>
//           )}
//           {isCustomerFormVisible && (
//             <div className="row">
//               <div className="col-12">
//                 <Formik
//                   enableReinitialize
//                   initialValues={initialValues}
//                   onSubmit={(values, { setSubmitting }) => {
//                     triggerCreateCustomer({ customer: values });
//                   }}
//                   validationSchema={validationSchema}
//                 >
//                   {({ ...props }) => (
//                     <Form noValidate>
//                       <CurrentCustomer {...props} onlyCustomer={true} />
//                     </Form>
//                   )}
//                 </Formik>
//               </div>
//             </div>
//           )}
//         </div>
//         <div className="h-pt--3 h-mb--3 h-align-items--center h-border--top">
//           {isProductFormVisible && (
//             <div className="row">
//               <div className="col-12">
//                 <p className="h-fz--14 h-fw--bold h-mb--2 h-color--gray-text">Producto</p>
//               </div>
//               <div className="col">
//                 <Select
//                   label="Tipo de servicio"
//                   placeholder="Seleccione"
//                   options={productsList}
//                   value={selectedProduct}
//                   onChange={event => triggerSelectProduct(event.target.value)}
//                   disabled={!productsList.length}
//                 />
//               </div>
//               <div className="col-3">
//                 <div className="search-btn">
//                   <Button
//                     className="h-mr--1"
//                     type="button"
//                     buttonType="secondary"
//                     onClick={triggerShowProductForm}
//                     disabled={!selectedCustomer}
//                   >
//                     Nuevo
//                   </Button>
//                 </div>
//               </div>
//             </div>
//           )}
//           {!isProductFormVisible && (
//             <div className="row">
//               <div className="col-12">
//                 <Formik
//                   enableReinitialize
//                   initialValues={initialValues}
//                   onSubmit={(values, { setSubmitting }) => {
//                     triggerCreateCustomer({ customer: values });
//                   }}
//                   validationSchema={validationSchema}
//                 >
//                   {({ ...props }) => (
//                     <div className="data-products" style={{ padding: 0 }}>
//                       <div className="title">Datos del producto</div>
//                       <Form noValidate>
//                         <ProductDetail {...props} product={initialValuesProduct} onlyProduct={true} />
//                       </Form>
//                     </div>
//                   )}
//                 </Formik>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//     <div className="container footer-ordes">
//       <div className="row">
//         <div className="col-5">
//           <Button
//             type="button"
//             buttonType="secondary"
//             disabled={!selectedCustomer && !isCustomerFormVisible && !selectedProduct}
//             onClick={triggerCleanSelections}
//           >
//             Cancelar
//           </Button>
//         </div>
//         <div className="col-5">
//           <Button
//             type="button"
//             buttonType="primary"
//             disabled={!selectedCustomer || !selectedProduct}
//             onClick={createNewOrder}
//           >
//             Siguiente
//           </Button>
//         </div>
//       </div>
//     </div>
//   </>
// );

export default StepOneContainer;
