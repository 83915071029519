import React from 'react';
import PropTypes from 'prop-types';

import { Title, Button, Table, Box } from 'components';

import TechnicianImportModal from '../TechnicianImportModal';
// import TechnicianFilters from '../TechnicianFilters';

import { Link } from 'react-router-dom';

const TechnicianList = ({ columns, technicianList, fetchMoreData, rememberSelection }) => (
  <Box
    title={<Title>Listado de técnicos</Title>}
    customActions={
      <Link to={{ pathname: '/technicians/new' }}>
        <Button type="button" buttonType="primary">
          Agregar
        </Button>
      </Link>
    }
  >
    {/* <CustomerFilters /> */}
    <Table
      fetchMoreData={fetchMoreData}
      columns={columns}
      data={technicianList}
      rememberSelection={rememberSelection}
    />
    <TechnicianImportModal />
  </Box>
);

TechnicianList.propTypes = {
  technicianList: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default TechnicianList;
