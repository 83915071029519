import React from 'react';
import { useSelector } from 'react-redux';
import { fromDashboard } from 'store/selectors';
import OrderSummary from './component';

const OrderSummaryContainer = () => {
  const orderSelectedCustomerFullName = useSelector(state => state.dashboard.order.selectedOrderCustomer.fullName);
  const orderSelectedCustomerProduct = useSelector(state => fromDashboard.getSelectedCustomerProduct(state));
  const orderSelectedTechnicianFullName = useSelector(state => fromDashboard.getSelectedOrderTechnician(state));
  const orderPrice = useSelector(state => state.dashboard.order.schedule.price);

  return (
    <OrderSummary
      {...{ orderSelectedCustomerFullName, orderSelectedCustomerProduct, orderSelectedTechnicianFullName, orderPrice }}
    />
  );
};

export default OrderSummaryContainer;
