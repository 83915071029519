import React from 'react';

import { Spinner, NavigationBar, Header } from 'components';

import './style.scss';

const AuthorizedTemplate = ({ children }) => {
  return (
    <>
      <Spinner />
      <NavigationBar />
      <main className="content">
        <Header />
        {children}
      </main>
    </>
  );
};

export default AuthorizedTemplate;
