import React from 'react';
import classNames from 'classnames';

import { Search, Button, Select, Datepicker, Toggle } from 'components';

import { CUSTOMER_CATEGORIES } from 'services/mockData';

import './style.scss';

const CustomerFilters = ({
  triggerAddCustomerFilters,
  triggerChangeCustomerSearchCriteria,
  triggerModalOpen,
  customerFilters,
  customerFiltersCount,
  customerSearchCriteria,
  isOpen,
  toggleIsOpen,
  cleanAndCloseFilters,
  selectWarranty
}) => (
  <>
    <div className="box-body__filter">
      <div className="c-filter">
        <div className="c-filter--open" onClick={toggleIsOpen}>
          <i className="icon-filter"></i>
          <span className="text">Filtro</span>
          <i className={isOpen ? 'icon-chevron-up' : 'icon-chevron-down'}></i>
          {customerFiltersCount !== 0 && (
            <span className="c-badget">
              <span className="nro">{customerFiltersCount}</span>
            </span>
          )}
        </div>
        {isOpen && (
          <div className="c-filter--close" onClick={cleanAndCloseFilters}>
            <span>Borrar</span>
          </div>
        )}
      </div>
      <Search
        placeholder="Buscar cliente..."
        onChange={e => triggerChangeCustomerSearchCriteria({ searchCriteria: e.target.value })}
        value={customerSearchCriteria}
      />
      <Button type="button" buttonType="secondary" onClick={triggerModalOpen}>
        Importar clientes
      </Button>
    </div>

    <div className={classNames('box-body__filter-content', { active: isOpen })}>
      <div className="form">
        <Select
          label="Tipo"
          placeholder="Seleccione"
          name="customerType"
          selectedOption={customerFilters.customerType}
          onChange={e => triggerAddCustomerFilters({ customerType: e.target.value })}
          options={CUSTOMER_CATEGORIES}
        />
        <Select
          label="Garantia"
          placeholder="Seleccione"
          name="warrantyType"
          selectedOption={customerFilters.warrantyType}
          onChange={e => triggerAddCustomerFilters({ warrantyType: e.target.value })}
          options={selectWarranty}
        />
        <Datepicker
          label="Desde"
          name="from"
          value={customerFilters.fromDate}
          onChange={e => triggerAddCustomerFilters({ fromDate: e })}
          maxDate={new Date()}
        />
        <Datepicker
          label="Hasta"
          name="to"
          value={customerFilters.toDate}
          onChange={e => triggerAddCustomerFilters({ toDate: e })}
          minDate={customerFilters.fromDate ? customerFilters.fromDate : new Date()}
        />
        <Toggle
          label="Clientes reportados"
          value={customerFilters.showReported}
          onChange={e => triggerAddCustomerFilters({ showReported: e })}
        />
      </div>
    </div>
  </>
);

export default CustomerFilters;
