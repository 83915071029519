import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import ForgotPasswordForm from './component';

import { Modal } from 'components';

import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { modalShow, modalHide } from 'store/actions';

import Auth from '@aws-amplify/auth';

const ForgotPasswordModalContainer = props => {
  const dispatch = useDispatch();
  const triggerCurrentModalHide = () => dispatch(modalHide('forgotPassword'));
  const triggerChangePasswordModalShow = payload => dispatch(modalShow('changePassword', payload));

  return (
    <Modal title="Olvidaste tu contraseña?" name="forgotPassword" closeable>
      <EnhancedForgotPasswordForm {...{ triggerCurrentModalHide, triggerChangePasswordModalShow }} {...props} />
    </Modal>
  );
};

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .max(30, 'Usuario muy largo.')
    .required('Este campo es obligatorio.')
});

const initialValues = { username: '' };

const handleSubmit = async (values, { setSubmitting, props }) => {
  try {
    const response = await Auth.forgotPassword(values.username);
    if (response) {
      props.triggerCurrentModalHide();
      props.triggerChangePasswordModalShow(values.username);
    }
    setSubmitting(false);
  } catch (e) {
    toast.error('Verifique que su nombre de usuario sea el correcto.');
    values.username = '';
    document.querySelector('.forgotPassword #username').focus();
  }
};

const EnhancedForgotPasswordForm = withFormik({
  mapPropsToValues: () => initialValues,
  validationSchema: validationSchema,
  handleSubmit: handleSubmit
})(ForgotPasswordForm);

export default ForgotPasswordModalContainer;
