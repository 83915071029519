import React from 'react';

import { WizardBullets } from 'components';

import { Wizard, WizardStep } from 'react-wizard-primitive';

import StepOne from './Steps/Step01';
import StepTwo from './Steps/Step02';
import StepThree from './Steps/Step03';
import StepFour from './Steps/Step04';
import StepFive from './Steps/Step05';

const stepsTitle = ['Creación', 'Programación', 'Revisión', 'En taller', 'Resumen'];

const CustomWizard = ({ initialStepIndex, children }) => (
  <Wizard initialStepIndex={initialStepIndex}>
    {({ activeStepIndex, goToStep, ...rest }) => {
      return (
        <>
          <WizardBullets {...{ stepsTitle, activeStepIndex, goToStep }} />
          {children}
        </>
      );
    }}
  </Wizard>
);

CustomWizard.Step = ({ children, routeTitle }) => (
  <WizardStep {...{ routeTitle }}>
    {({ isActive, ...rest }) => isActive && <>{React.cloneElement(children, { ...rest })}</>}
  </WizardStep>
);

const OrderWizard = ({ initialStepIndex }) => (
  <CustomWizard initialStepIndex={initialStepIndex}>
    <CustomWizard.Step routeTitle="creation">
      <StepOne />
    </CustomWizard.Step>
    <CustomWizard.Step routeTitle="schedule">
      <StepTwo />
    </CustomWizard.Step>
    <CustomWizard.Step routeTitle="revision">
      <StepThree />
    </CustomWizard.Step>
    <CustomWizard.Step routeTitle="repair">
      <StepFour />
    </CustomWizard.Step>
    <CustomWizard.Step routeTitle="resume">
      <StepFive />
    </CustomWizard.Step>
  </CustomWizard>
);

export default OrderWizard;
