import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Session from 'services/session';
import { LoginPage, NotFoundPage, CustomersPage, OrdersPage, TechniciansPage } from 'components';

window.Session = Session;
const App = () => {
  return (
    <>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Redirect exact from="/" to="/customers" />
        <ProtectedRoute path="/customers" component={CustomersPage} />
        <ProtectedRoute path="/orders" component={OrdersPage} />
        <ProtectedRoute path="/technicians" component={TechniciansPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
};

const ProtectedRoute = ({ component: Component, render = null, ...props }) => {
  function renderComponentOrRenderCall(props) {
    if (Component) {
      return <Component {...props} />;
    } else if (render) {
      return render(props);
    }
  }

  return (
    <Route
      {...props}
      render={props =>
        Session.isAuthenticated() ? (
          renderComponentOrRenderCall(props)
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { origin: props.location.pathname }
            }}
          />
        )
      }
    />
  );
};

export default App;
