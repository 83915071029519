/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

import Input from 'components/atoms/Input';

const TextField = ({ errorMessage, disabled, hasError, customIcon, label, className, ...attributes }) => (
  <div className={classNames('c-input', { error: hasError }, className)}>
    <label>
      <Input {...attributes} disabled={disabled} />
      <span className={classNames('label', { disabled })}>{label}</span>
    </label>
    {customIcon && <i className={customIcon} />}
    {hasError && <span className="label-error">{errorMessage}</span>}
  </div>
);

TextField.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  customIcon: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.string
};

TextField.defaultProps = {
  attributes: [],
  className: '',
  customIcon: '',
  disabled: false,
  hasError: false,
  errorMessage: '',
  label: ''
};

export default TextField;
