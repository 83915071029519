import React from 'react';
import OrdersPageRoutes from './routes';

import { AuthorizedTemplate } from 'components';

const CustomerPage = () => (
  <AuthorizedTemplate>
    <OrdersPageRoutes />
  </AuthorizedTemplate>
);

export default CustomerPage;
