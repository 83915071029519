import React from 'react';
import { useSelector } from 'react-redux';
import { pending } from 'redux-saga-thunk';

import TableSpinner from './component';

const TableSpinnerContainer = () => {
  const showSpinner = useSelector(state => pending(state, ['customersConcatListRead']));
  return <TableSpinner showSpinner={showSpinner} />;
};

export default TableSpinnerContainer;
