import React from 'react';
import CustomersPageRoutes from './routes';

import { AuthorizedTemplate } from 'components';

const CustomerPage = () => (
  <AuthorizedTemplate>
    <CustomersPageRoutes />
  </AuthorizedTemplate>
);

export default CustomerPage;
