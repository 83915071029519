import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import './style.scss';

const Breadcrumbs = ({ className, links }) => {
  const listLength = links.length;
  return (
    <div className="c-breadcrumbs">
      {links.map((link, index) => {
        const currentClass = `c-breadcrumbs__link${index + 1 === listLength ? '--active' : ''}`;
        return (
          <a className={classNames(currentClass, className)} key={link.url} href={link.url}>
            {link.text}
          </a>
        );
      })}
    </div>
  );
};

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.object).isRequired
};

Breadcrumbs.defaultProps = {
  className: ''
};

export default Breadcrumbs;
