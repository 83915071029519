import React from 'react';
import { Form, FieldArray } from 'formik';
import { Link } from 'react-router-dom';

import CustomerDetails from '../CustomerDetails';
import CustomerProductForm from '../CustomerProductForm';
import { Button } from 'components';

import './style.scss';

const CustomerForm = ({ cancelButtonText, submitButtonText, ...formikProps }) => (
  <Form noValidate autoComplete="off">
    <CustomerDetails {...formikProps} />
    <FieldArray name="products" validateOnChange={false} component={CustomerProductForm} />
    <div className="box-body__btn">
      <Link to={{ pathname: '/customers' }}>
        <Button buttonType="secondary">{cancelButtonText}</Button>
      </Link>
      <Button
        buttonType="primary"
        type="submit"
        disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
      >
        {submitButtonText}
      </Button>
    </div>
  </Form>
);

export default CustomerForm;
