// https://github.com/diegohaz/arc/wiki/Sagas
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
// import Auth from '@aws-amplify/auth';

import { put, call, takeLatest, cancel } from 'redux-saga/effects';
import * as actions from './actions';

export function* createResource(api, { data }, { resource, thunk }) {
  try {
    // https://github.com/diegohaz/arc/wiki/API-service
    const detail = yield call([api, api.post], `/${resource}`, data);
    // https://github.com/diegohaz/arc/wiki/Actions#async-actions
    yield put(actions.resourceCreateSuccess(resource, detail, { data }, thunk));
  } catch (e) {
    yield put(actions.resourceCreateFailure(resource, e, { data }, thunk));
  }
}

export function* readResourceList(api, { params }, { resource, thunk }) {
  try {
    const list = yield call([api, api.get], `/${resource}`, { params });
    yield put(actions.resourceListReadSuccess(resource, list, { params }, thunk));
    yield cancel();
  } catch (e) {
    yield put(actions.resourceListReadFailure(resource, e, { params }, thunk));
  }
}

export function* readResourceConcatList(api, { params }, { resource, thunk }) {
  try {
    const list = yield call([api, api.get], `/${resource}`, { params });
    yield put(actions.resourceConcatListReadSuccess(resource, list, { params }, thunk));
    yield cancel();
  } catch (e) {
    yield put(actions.resourceConcatListReadFailure(resource, e, { params }, thunk));
  }
}

export function* readResourceSelectList(api, { params }, { resource, thunk }) {
  try {
    const rawResource = resource.toLowerCase().replace('select', '');
    const list = yield call([api, api.get], `/selects/${rawResource}`, { params });
    yield put(actions.resourceSelectListReadSuccess(resource, list, { params }, thunk));
    yield cancel();
  } catch (e) {
    yield put(actions.resourceSelectListReadFailure(resource, e, { params }, thunk));
  }
}

export function* readResourceDetail(api, { needle }, { resource, thunk }) {
  try {
    const detail = yield call([api, api.get], `/${resource}/${needle}`);
    yield put(actions.resourceDetailReadSuccess(resource, detail, { needle }, thunk));
  } catch (e) {
    yield put(actions.resourceDetailReadFailure(resource, e, { needle }, thunk));
  }
}

export function* updateResource(api, { needle, data }, { resource, thunk }) {
  try {
    const detail = yield call([api, api.put], `/${resource}/${needle}`, data);
    yield put(actions.resourceUpdateSuccess(resource, detail, { needle, data }, thunk));
  } catch (e) {
    yield put(actions.resourceUpdateFailure(resource, e, { needle, data }, thunk));
  }
}

export function* deleteResource(api, { needle }, { resource, thunk }) {
  try {
    yield call([api, api.delete], `/${resource}/${needle}`);
    yield put(actions.resourceDeleteSuccess(resource, { needle }, thunk));
  } catch (e) {
    yield put(actions.resourceDeleteFailure(resource, e, { needle }, thunk));
  }
}

export function* watchResourceCreateRequest(api, { payload, meta }) {
  yield call(secureRequest, api);
  yield call(createResource, api, payload, meta);
}

export function* watchResourceListReadRequest(api, { payload, meta }) {
  yield call(secureRequest, api);
  yield call(readResourceList, api, payload, meta);
}

export function* watchResourceConcatListReadRequest(api, { payload, meta }) {
  yield call(secureRequest, api);
  yield call(readResourceConcatList, api, payload, meta);
}

export function* watchResourceSelectListReadRequest(api, { payload, meta }) {
  yield call(secureRequest, api);
  yield call(readResourceSelectList, api, payload, meta);
}

export function* watchResourceDetailReadRequest(api, { payload, meta }) {
  yield call(secureRequest, api);
  yield call(readResourceDetail, api, payload, meta);
}

export function* watchResourceUpdateRequest(api, { payload, meta }) {
  yield call(secureRequest, api);
  yield call(updateResource, api, payload, meta);
}

export function* watchResourceDeleteRequest(api, { payload, meta }) {
  yield call(secureRequest, api);
  yield call(deleteResource, api, payload, meta);
}

function* secureRequest(api) {
  // const session = yield Auth.currentSession();
  // api.setToken(session.getIdToken().getJwtToken());
}

export default function*({ api }) {
  yield takeLatest(actions.RESOURCE_CREATE_REQUEST, watchResourceCreateRequest, api);
  yield takeLatest(actions.RESOURCE_LIST_READ_REQUEST, watchResourceListReadRequest, api);
  yield takeLatest(actions.RESOURCE_CONCAT_LIST_READ_REQUEST, watchResourceConcatListReadRequest, api);
  yield takeLatest(actions.RESOURCE_SELECT_LIST_READ_REQUEST, watchResourceSelectListReadRequest, api);
  yield takeLatest(actions.RESOURCE_DETAIL_READ_REQUEST, watchResourceDetailReadRequest, api);
  yield takeLatest(actions.RESOURCE_UPDATE_REQUEST, watchResourceUpdateRequest, api);
  yield takeLatest(actions.RESOURCE_DELETE_REQUEST, watchResourceDeleteRequest, api);
}
