import React from 'react';
import Switch from 'react-switch';

import './style.scss';

const Toggle = ({ label, onChange, value }) => (
  <div className="c-toggle">
    <label>{label}</label>
    <Switch
      className="c-switch"
      uncheckedIcon={null}
      checkedIcon={null}
      handleDiameter={14}
      height={18}
      width={32}
      offColor="#ffffff"
      onColor="#375093"
      offHandleColor="#576271"
      onHandleColor="#ffffff"
      checked={value}
      onChange={onChange}
    ></Switch>
  </div>
);

export default Toggle;
