import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { NavigationElement } from 'components';
import reysepLogo from 'images/logo-blue.svg';

import { isDev } from 'config';

import './style.scss';

const NavigationBar = () => {
  const navigationBar = useSelector(state => state.dashboard.navigationBar);

  return (
    <div className={classNames('nav', { active: navigationBar })}>
      <div className="nav__logo">
        <Link to={{ pathname: '/' }}>
          <img alt="logo" src={reysepLogo} />
        </Link>
      </div>
      <div className="nav__menu">
        <NavigationElement header="Clientes" headerLink="/customers" icon="icon-client" />
        {isDev && <NavigationElement header="Ordenes" headerLink="/orders" icon="icon-order" />}
        {isDev && <NavigationElement header="Técnicos" headerLink="/technicians" icon="icon-client" />}
      </div>
    </div>
  );
};

export default withRouter(NavigationBar);
