import React, { useRef } from 'react';
import CustomerImportModal from './component';

const CustomerImportModalContainer = props => {
  const fileInputRef = useRef(null);

  const openFileInput = event => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  return <CustomerImportModal {...{ fileInputRef, openFileInput }} {...props} />;
};

export default CustomerImportModalContainer;
