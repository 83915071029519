import React from 'react';

import './style.scss';

const TableSpinner = ({ showSpinner }) => {
  return (
    showSpinner && (
      <div className="c-table-spinner">
        <div className="c-table-spinner--circle">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  );
};

export default TableSpinner;
