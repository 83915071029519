import React from 'react';

import { Form } from 'formik';
import { Title, TextField, Button } from 'components';

const NewPasswordForm = ({ errors, touched, handleBlur, handleChange, isSubmitting, dirty, isValid, values }) => (
  <Form className="newPassword">
    <Title className="c-title h-mb--2">Bienvenido</Title>
    <div className="c-modal__description h-mb--2">Cambie su contraseña por defecto</div>
    <TextField
      label="Nueva Contraseña"
      id="newPassword"
      inputType="password"
      name="newPassword"
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.newPassword}
      hasError={errors.newPassword && touched.newPassword}
      errorMessage={errors.newPassword}
    />
    <TextField
      label="Verifique Contraseña"
      id="newPasswordConfirmation"
      inputType="password"
      name="newPasswordConfirmation"
      value={values.newPasswordConfirmation}
      onChange={handleChange}
      onBlur={handleBlur}
      hasError={errors.newPasswordConfirmation && touched.newPasswordConfirmation}
      errorMessage={errors.newPasswordConfirmation}
    />
    <Button className="h-mt--2" buttonType="primary" type="submit" disabled={isSubmitting || !dirty || !isValid}>
      Enviar
    </Button>
  </Form>
);

export default NewPasswordForm;
