import React from 'react';

import { Button, Datepicker, Select, TextField } from 'components';
import InputMask from 'react-input-mask';

import { ORDER_SERVICES_TYPES } from 'services/mockData';
import OrderSummary from '../../Summary';

const StepTwo = ({
  triggerNextStep,
  orderTechnicianList,
  orderCreationDate,
  orderReasonOfService,
  orderTypeOfService,
  orderTechnician,
  orderScheduleDate,
  orderHour,
  orderPrice,
  triggerChangeOrderValuePerPath
}) => {
  return (
    <>
      <OrderSummary />
      <div className="container">
        <div className="step-orders step-orders--two">
          <div className="h-pt--3 h-mb--3 h-align-items--center">
            <div className="row">
              <div className="col-10">
                <p className="h-fz--14 h-fw--bold h-mb--2 h-color--gray-text">Datos de la orden</p>
              </div>
              <div className="col-2">
                <div className="c-btn--icon">
                  <i className="icon-save"></i>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4 h-mb--2">
                <Datepicker label="Fecha de creación" name="creationDate" value={orderCreationDate} disabled />
              </div>
              <div className="col-4 h-mb--2">
                <Select
                  label="Tipo de servicio"
                  placeholder="Seleccione"
                  value={orderTypeOfService}
                  onChange={e =>
                    triggerChangeOrderValuePerPath({ key: 'order.schedule.typeOfService', value: e.target.value })
                  }
                  options={ORDER_SERVICES_TYPES}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-8 h-mb--2">
                <TextField
                  label="Motivo de la llamada"
                  id="reasonOfService"
                  name="reasonOfService"
                  value={orderReasonOfService}
                  onChange={e =>
                    triggerChangeOrderValuePerPath({ key: 'order.schedule.reasonOfService', value: e.target.value })
                  }
                  // value={values.newPasswordConfirmation}
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                  // hasError={errors.newPasswordConfirmation && touched.newPasswordConfirmation}
                  // errorMessage={errors.newPasswordConfirmation}
                />
              </div>
            </div>
          </div>
          <div className="h-pt--3 h-align-items--center h-border--top">
            <div className="row">
              <div className="col-12">
                <p className="h-fz--14 h-fw--bold h-mb--2 h-color--gray-text">Datos de la programación</p>
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col-4 h-mb--2">
                  <Datepicker
                    label="Fecha de programación"
                    placeholder="dd/mm/aaaa"
                    value={orderScheduleDate}
                    onChange={e => triggerChangeOrderValuePerPath({ key: 'order.schedule.date', value: e })}
                  />
                </div>
                <div className="col-4 h-mb--2">
                  <Datepicker
                    label="Hora:"
                    pickerType="time"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Hora"
                    dateFormat="h:mm aa"
                    placeholder="hh:mm"
                    name="hour"
                    value={orderHour}
                    onChange={e => triggerChangeOrderValuePerPath({ key: 'order.schedule.hour', value: e })}
                  />
                </div>
                <div className="col-4 h-mb--2">
                  <Select
                    label="Técnico"
                    placeholder="Seleccione"
                    value={orderTechnician}
                    valueKey={'_id'}
                    onChange={e =>
                      triggerChangeOrderValuePerPath({
                        key: 'order.schedule.technician',
                        value: e.target.value
                      })
                    }
                    options={orderTechnicianList}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4 h-mb--2">
                  <div className="c-input">
                    <label>
                      <InputMask
                        label="Precio"
                        mask="S/. 999.99"
                        value={orderPrice}
                        onChange={e =>
                          triggerChangeOrderValuePerPath({ key: 'order.schedule.price', value: e.target.value })
                        }
                      />
                      <span className="label">Precio</span>
                    </label>
                  </div>
                </div>
                <div className="col-3">
                  {false && (
                    <Button className="h-mt--1 c-btn--no-block" type="button" buttonType="secondary">
                      Reprogramar
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container footer-ordes">
        <div className="row">
          <div className="col-5">
            <Button type="button" buttonType="secondary">
              Cancelar
            </Button>
          </div>
          <div className="col-5">
            <Button type="button" buttonType="primary" onClick={triggerNextStep}>
              Siguiente
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepTwo;
