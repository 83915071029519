import React from 'react';
import { Link } from 'react-router-dom';

import reysepLogo from 'images/logo-blue.svg';
import notFound from 'images/not-found.png';

import './style.scss';

const NotFoundPage = () => (
  <div className="not-found">
    <div className="not-found__logo">
      <img alt="Reysep" src={reysepLogo} />
    </div>
    <div className="not-found__content">
      <img alt="Reysep" src={notFound} />
      <div>
        <p className="nro">404</p>
        <p className="txt">PAGINA NO ENCONTRADA</p>
        <Link to={{ pathname: '/' }}>
          <span className="c-link">Regresar al inicio</span>
        </Link>
      </div>
    </div>
  </div>
);

export default NotFoundPage;
