const merge = require('lodash/merge');

const config = {
  all: {
    env: process.env.NODE_ENV || 'development',
    isDev: process.env.NODE_ENV !== 'production',
    isProd: process.env.NODE_ENV === 'production',
    basename: process.env.PUBLIC_PATH,
    isBrowser: typeof window !== 'undefined',
    // apiUrl: process.env.REACT_APP_API_URL || 'https://api-orders.reysep.com/staging',
    apiUrl: process.env.REACT_APP_API_URL || 'https://reysep-production.herokuapp.com',
    // fbAppId: '991453140998882',
    // googleClientId: '464712936089-q953apdu1bjiqtcjndktnnk1ts4f2cgv.apps.googleusercontent.com',
    // gtmId: 'GTM-WX5ZNVC',
    featureFlags: [
      {
        name: 'loginWithCognito',
        description: 'Use AWS Cognito to login',
        active: false
      }
    ]
  },
};

module.exports = merge(config.all, config[config.all.env]);
