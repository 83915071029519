import React from 'react';

import { Form } from 'formik';
import { Title, TextField, Button } from 'components';

const ChangePasswordForm = ({ errors, touched, handleBlur, handleChange, isSubmitting, dirty, isValid, values }) => (
  <Form className="changePassword">
    <Title className="c-title h-mb--2">Nueva contraseña</Title>
    <div className="c-modal__description h-mb--2">
      Se envió un código de verificación a su correo o número de celular.
    </div>
    <TextField
      id="code"
      inputType="text"
      name="code"
      value={values.code}
      onChange={handleChange}
      onBlur={handleBlur}
      hasError={errors.code && touched.code}
      errorMessage={errors.code}
      label="Código"
    />
    <div className="c-modal__description h-mb--2">
      Ahora ingrese su nueva contraseña y confirmela para proceder con el cambio.
    </div>
    <TextField
      label="Contraseña"
      id="newPassword"
      inputType="password"
      name="newPassword"
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.newPassword}
      hasError={errors.newPassword && touched.newPassword}
      errorMessage={errors.newPassword}
    />
    <TextField
      label="Verifique Contraseña"
      id="newPasswordConfirmation"
      inputType="password"
      name="newPasswordConfirmation"
      value={values.newPasswordConfirmation}
      onChange={handleChange}
      onBlur={handleBlur}
      hasError={errors.newPasswordConfirmation && touched.newPasswordConfirmation}
      errorMessage={errors.newPasswordConfirmation}
    />
    <Button className="h-mt--2" buttonType="primary" type="submit" disabled={isSubmitting || !dirty || !isValid}>
      Enviar
    </Button>
  </Form>
);

export default ChangePasswordForm;
