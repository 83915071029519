import React from 'react';

import { useDispatch } from 'react-redux';
import { modalShow } from 'store/actions';
import LoginPage from './component';

const LoginPageContainer = props => {
  const dispatch = useDispatch();
  const triggerModalOpen = () => dispatch(modalShow('forgotPassword'));
  const triggerChangePasswordModalShow = payload => dispatch(modalShow('changePassword', payload));
  const triggerNewPasswordModalShow = payload => dispatch(modalShow('newPassword', payload));

  return (
    <LoginPage {...{ triggerChangePasswordModalShow, triggerNewPasswordModalShow, triggerModalOpen }} {...props} />
  );
};

export default LoginPageContainer;
