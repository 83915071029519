import React from 'react';

import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentOrder } from 'store/dashboard/actions';
import { fromEntities, fromResource } from 'store/selectors';

import OrderWizard from './component';

const OrderWizardContainer = props => {
  const { orderId } = useParams();
  console.log('orderId', orderId);
  const dispatch = useDispatch();

  const orderList = useSelector(state => fromEntities.getList(state, 'orders', fromResource.getList(state, 'orders')));
  const selectedOrderNumber = useSelector(state => state.dashboard.order.orderNumber);

  const getCurrentInitialStepIndex = () => 0;

  React.useEffect(() => {
    if (orderList.length && orderId && !selectedOrderNumber) {
      const triggerSelectCurrentOrder = payload => dispatch(selectCurrentOrder(payload));
      const currentOrder = orderList.filter(order => order.orderNumber === orderId)[0];
      triggerSelectCurrentOrder(currentOrder);
    }
  }, [orderId, dispatch, orderList, selectedOrderNumber]);

  return <OrderWizard initialStepIndex={getCurrentInitialStepIndex()} {...props} />;
};

export default OrderWizardContainer;
