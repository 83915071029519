import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Dot = ({ type }) => {
  return <div className={`c-dot ${type}`}></div>;
};

Dot.propTypes = {
  type: PropTypes.oneOf(['default', 'primary', 'success', 'error'])
};

Dot.defaultProps = {
  type: 'default'
};

export default Dot;
