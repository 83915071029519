export const DISTRICTS = [
  { text: 'Trujillo', value: 'Trujillo' },
  { text: 'Chiclayo', value: 'Chiclayo' }
];

export const CUSTOMER_CATEGORIES = [
  { text: 'Nuevo', value: 'new' },
  { text: 'Contactado', value: 'contacted' },
  { text: 'Pendiente', value: 'pending' }
];
export const DOCUMENT_TYPE = [
  { text: 'DNI', value: 'DNI' },
  { text: 'RUC', value: 'RUC' }
];

export const ORDER_SERVICES_TYPES = [
  {
    text: 'Instalación',
    value: 'instalation'
  },
  {
    text: 'Servicio Particular',
    value: 'particular'
  }
];

export const TECHNICIANS = [
  {
    text: 'Alexander',
    value: 'T00001'
  },
  {
    text: 'Gonzalo',
    value: 'T00002'
  }
];

export const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY';

export const PRODUCTS_MOCK_DATA = [
  {
    text: 'Sole',
    value: 'sole',
    artifacts: [
      {
        text: 'Calentador a gas',
        value: 'warmer',
        models: [
          {
            text: 'SOLEGASN05C DE 5.5LTS. G. NATURAL',
            value: 1
          },
          {
            text: 'SOLEGAS506MCT DE 6LYS. GLP',
            value: 2
          }
        ]
      },
      {
        text: 'Campana extractora',
        value: 'extractor',
        models: [
          {
            text: 'TURE69CO CAMPANA VENUS PREMIUM',
            value: 3
          },
          {
            text: 'TURE63CO CAMPANA VENUS PLUSS',
            value: 4
          }
        ]
      }
    ]
  }
];
