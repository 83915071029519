import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeOrderValuePerPath } from 'store/actions';

import StepThree from './component';

const StepTwoContainer = ({ nextStep }) => {
  const dispatch = useDispatch();

  const orderCreationDate = useSelector(state => state.dashboard.order.creationDate);
  const orderTypeOfService = useSelector(state => state.dashboard.order.schedule.typeOfService);
  const orderTechnician = useSelector(state => state.dashboard.order.schedule.technician);
  const orderReasonOfService = useSelector(state => state.dashboard.order.schedule.reasonOfService);
  const orderHour = useSelector(state => state.dashboard.order.schedule.hour);
  const orderScheduleDate = useSelector(state => state.dashboard.order.schedule.date);

  const triggerChangeOrderValuePerPath = payload => dispatch(changeOrderValuePerPath(payload));
  const triggerNextStep = () => true && nextStep();

  return (
    <StepThree
      {...{
        triggerNextStep,
        orderCreationDate,
        orderTypeOfService,
        orderReasonOfService,
        orderTechnician,
        orderScheduleDate,
        orderHour,
        triggerChangeOrderValuePerPath
      }}
    />
  );
};

export default StepTwoContainer;
