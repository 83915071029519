import { initialState } from './selectors';
import {
  NAVIGATION_BAR_SHOW,
  NAVIGATION_BAR_HIDE,
  CUSTOMERS_CHANGE_FILTER,
  CUSTOMERS_CLEAN_FILTER,
  CUSTOMERS_CHANGE_SEARCH_CRITERIA,
  CUSTOMERS_GET_NEW_PAGE,
  ORDER_CLEAN_SELECTED_ORDER_CUSTOMER,
  ORDER_CHANGE_CUSTOMER_SEARCH_CRITERIA,
  ORDER_SHOW_CUSTOMER_FORM,
  ORDER_SHOW_PRODUCT_FORM,
  ORDER_SELECT_CUSTOMER,
  ORDER_SELECT_CUSTOMER_PRODUCT,
  ORDER_SELECT_CURRENT,
  ORDER_CHANGE_VALUE_PER_PATH
} from './actions';

const set = require('set-value');

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case NAVIGATION_BAR_SHOW:
      return {
        ...state,
        navigationBar: true
      };
    case NAVIGATION_BAR_HIDE:
      return {
        ...state,
        navigationBar: false
      };

    // Customer

    case CUSTOMERS_GET_NEW_PAGE:
      return Object.assign({}, state, {
        customers: { ...state.customers, nextPage: state.customers.nextPage + 1 }
      });

    case CUSTOMERS_CHANGE_FILTER:
      const newFilters = Object.assign({}, state.customers.filters, { ...payload });
      const newFiltersAppliedCount = Object.values(newFilters).filter(value => Boolean(value)).length;
      return Object.assign({}, state, {
        customers: { ...state.customers, filters: newFilters, filtersApplied: newFiltersAppliedCount }
      });
    case CUSTOMERS_CLEAN_FILTER:
      return Object.assign({}, state, { customers: initialState.customers });

    case CUSTOMERS_CHANGE_SEARCH_CRITERIA:
      return Object.assign({}, state, { customers: { ...state.customers, searchCriteria: payload.searchCriteria } });

    // Order

    case ORDER_SELECT_CURRENT:
      return Object.assign({}, state, {
        order: { ...state.order, orderNumber: payload.orderNumber }
      });
    case ORDER_CHANGE_CUSTOMER_SEARCH_CRITERIA:
      return Object.assign({}, state, {
        order: { ...state.order, customerSearchCriteria: payload.searchCriteria }
      });

    case ORDER_SHOW_CUSTOMER_FORM:
      return Object.assign({}, state, {
        order: { ...state.order, isCustomerFormVisible: true }
      });

    case ORDER_SHOW_PRODUCT_FORM:
      return Object.assign({}, state, {
        order: { ...state.order, isProductFormVisible: true }
      });

    case ORDER_SELECT_CUSTOMER:
      return Object.assign({}, state, {
        order: {
          ...state.order,
          selectedOrderCustomer: payload,
          selectedOrderCustomerProductList: payload
            ? payload.products.map(product => ({ text: `${product.brand} ${product.model}`, value: product._id }))
            : []
        }
      });

    case ORDER_SELECT_CUSTOMER_PRODUCT:
      return Object.assign({}, state, {
        order: {
          ...state.order,
          selectedOrderCustomerProduct: payload
        }
      });

    case ORDER_CLEAN_SELECTED_ORDER_CUSTOMER:
      return Object.assign({}, state, {
        order: {
          ...state.order,
          selectedOrderCustomer: {},
          selectedOrderCustomerProductList: [],
          selectedOrderCustomerProduct: ''
        }
      });
    case ORDER_CHANGE_VALUE_PER_PATH:
      return Object.assign({}, state, set(state, payload.key, payload.value));
    default:
      return state;
  }
};
