import React, { useEffect, useCallback, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { fromEntities, fromResource } from 'store/selectors';
import { resourceListReadRequest } from 'store/actions';

import { Link } from 'react-router-dom';

import { Checkbox } from 'components';
import TechnicianList from './component';

const TechnicianListContainer = () => {
  const dispatch = useDispatch();

  const technicianList = useSelector(state =>
    fromEntities.getList(state, 'employees', fromResource.getList(state, 'employees'))
  );

  // const nextPage = useSelector(state => state.dashboard.customers.nextPage);
  // const { canFetchMoreResults, rememberSelection, nextPage } = useSelector(state =>
  //     fromResource.getResourceState(state, 'technicians')
  // );

  // const technicianSearchCriteria = useSelector(state => state.dashboard.technicians.searchCriteria);
  // const technicianFilters = useSelector(state => state.dashboard.technicians.filters);

  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Cell: ({ row }) => <Checkbox name={`${row.original._id}-selected`} {...row.getToggleRowSelectedProps()} />
      },
      {
        Header: 'Nombre',
        Cell: ({ row }) => row.original.firstName
      },
      {
        Header: 'Apellido',
        Cell: ({ row }) => row.original.lastName
      },
      {
        Header: 'Cognito',
        Cell: ({ row }) => (row.original.hasCognitoUser === true ? 'activo' : 'inactivo')
      },
      {
        id: 'editButton',
        Cell: ({ row }) => (
          <Link className="c-link" to={{ pathname: `/technicians/${row.original._id}/edit` }}>
            Ver detalles
          </Link>
        )
      }
    ],
    []
  );

  const fetchTechnicians = useCallback(() => {
    const filters = { role: 'technician' };
    dispatch(resourceListReadRequest('employees', filters));
  }, [dispatch]);

  useEffect(() => {
    fetchTechnicians();
  }, [fetchTechnicians]);

  return (
    <TechnicianList
      {...{
        columns,
        technicianList
      }}
    />
  );
};

export default TechnicianListContainer;
