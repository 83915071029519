import React from 'react';
import PropTypes from 'prop-types';

import { Icon, DropdownMenu } from 'components';

import './style.scss';

const Header = ({ isAuthenticated, toggleNavigation, fullName, signOut }) => {
  const userGreetings = () => {
    return (
      <DropdownMenu
        heading={
          <p className="name">
            Bienvenido <span>{fullName}</span>
          </p>
        }
        items={[
          <div key="signout" onClick={signOut}>
            <span>Cerrar sesión</span>
          </div>
        ]}
      />
    );
  };

  return (
    <div className="header">
      <div className="header__nav">
        <Icon icon="icon-nav" onClick={toggleNavigation} />
      </div>
      <div className="header__profile">{isAuthenticated && userGreetings()}</div>
    </div>
  );
};

Header.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  fullName: PropTypes.string.isRequired,
  toggleNavigation: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired
};

export default Header;
