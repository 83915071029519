import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const ClickableMenuItem = ({ onClick, children }) => (
  <div className="box-nav-item box-nav-item--search">
    <label className="h-flex--center-v">
      <button onClick={onClick} className="c-clickable-menu-item">
        {children}
      </button>
    </label>
  </div>
);

ClickableMenuItem.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default ClickableMenuItem;
