import React from 'react';
import classNames from 'classnames';

import './style.scss';

const WizardBullets = ({ stepsTitle, activeStepIndex, goToStep }) => (
  <div className="c-step">
    {stepsTitle.map((item, index) => (
      <div
        key={`step-${index}`}
        onClick={() => index < activeStepIndex && goToStep(index)}
        className={classNames('c-step__cont', {
          'c-step__cont--active': index === activeStepIndex,
          'c-step__cont--check': index < activeStepIndex
        })}
      >
        <div className="dot">
          <span>{index + 1}</span>
        </div>
        <div className="text">{item}</div>
      </div>
    ))}
  </div>
);

export default WizardBullets;
