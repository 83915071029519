import React from 'react';
import PropTypes from 'prop-types';

import ReactModal from 'react-modal';

import './style.scss';

const Modal = ({ children, title, closeable, onClose, ...props }) => {
  const hasHeader = title || closeable;
  return (
    <ReactModal
      closeTimeoutMS={250}
      contentLabel={title || 'Modal'}
      onRequestClose={onClose}
      hasHeader={hasHeader}
      ariaHideApp={false}
      overlayClassName={'overlay'}
      className={'c-modal'}
      {...props}
    >
      <span className="close">
        <i className="icon-close" onClick={onClose}></i>
      </span>
      <div className="center">{children}</div>
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  closeable: PropTypes.bool,
  reverse: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

export default Modal;
