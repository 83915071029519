import React from 'react';

import { Datepicker, Select, TextField } from 'components';

import { CUSTOMER_CATEGORIES } from 'services/mockData';

const StepFour = () => {
  return (
    <>
      <div className="step-orders--summary">
        <div className="row">
          <div className="col-3">
            <div className="info">
              <span className="label">Cliente</span>
              <span className="date">Alexander Llanos Ruiz</span>
            </div>
          </div>
          <div className="col-3">
            <div className="info">
              <span className="label">Producto</span>
              <span className="date">Lavadora</span>
            </div>
          </div>
          <div className="col-3">
            <div className="info">
              <span className="label">Técnico</span>
              <span className="date">Alexander Choy</span>
            </div>
          </div>
          <div className="col-3">
            <div className="info h-text-align--right">
              <span className="label">Precio final</span>
              <span className="date">
                <b>S/ 100.00</b>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="step-orders step-orders--three">
          <div className="h-pt--3 h-mb--3 h-align-items--center">
            <div className="row">
              <div className="col-10">
                <p className="h-fz--14 h-fw--bold h-mb--2 h-color--gray-text">Datos del taller</p>
              </div>
              <div className="col-2">
                <div className="c-btn--icon">
                  <i className="icon-save"></i>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4 h-mb--2">
                <Select label="Técnico" placeholder="Seleccione" options={CUSTOMER_CATEGORIES} />
              </div>
              <div className="col-4 h-mb--2">
                <Select label="Tipo de servicio" placeholder="Seleccione" options={CUSTOMER_CATEGORIES} />
              </div>
            </div>
            <div className="row">
              <div className="col-8 h-mb--2">
                <TextField placeholder="Detalle de revision" />
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <Datepicker label="Fecha entrada" placeholder="dd/mm/aaaa" value={new Date()} />
              </div>
              <div className="col-4">
                <Datepicker label="Fecha salida" placeholder="dd/mm/aaaa" value={new Date()} />
              </div>
            </div>
          </div>
        </div>
        <div className="h-pt--3 h-align-items--center h-border--top">
          <div className="row">
            <div className="col-12">
              <p className="h-fz--14 h-fw--bold h-color--gray-text">Listado de piezas</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="c-table-piece h-mt--2 h-mb--2">
                <div className="c-table-piece--header">
                  <span className="item">Nombre</span>
                  <span className="item h-text-align--right h-mr--10">Precio compra</span>
                  <span className="item h-text-align--right">Precio venta</span>
                  <div className="item-trash"></div>
                </div>
                <div className="c-table-piece--body">
                  <div className="list">
                    <span className="item">Tuerca</span>
                    <span className="item h-text-align--right h-mr--10">S/1.00</span>
                    <span className="item h-text-align--right">S/1.50</span>
                    <div className="item-trash"></div>
                  </div>
                  <div className="list">
                    <span className="item">Tuerca</span>
                    <span className="item h-text-align--right h-mr--10">S/1.00</span>
                    <span className="item h-text-align--right">S/1.50</span>
                    <div className="item-trash"></div>
                  </div>
                </div>
                <div className="c-table-piece--footer">
                  <span className="item">TOTAL PIEZAS</span>
                  <span className="item h-text-align--right">S/3.00</span>
                  <div className="item-trash"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepFour;
