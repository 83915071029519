import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import './style.scss';

const Button = ({ buttonType, children, disabled, onClick, type, className, ...attributes }) => {
  const computedClassName =
    buttonType === 'link'
      ? classNames('c-link', { disabled }, className)
      : classNames('c-btn', `c-btn--${buttonType}${(disabled && '__disabled') || ''}`, className);
  /* eslint-disable react/button-has-type */
  return (
    <button type={type} className={computedClassName} onClick={onClick} {...attributes} disabled={disabled}>
      {children}
    </button>
  );
  /* eslint-enable react/button-has-type */
};

Button.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  buttonType: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string
};

Button.defaultProps = {
  attributes: [],
  className: '',
  disabled: false,
  type: 'button'
};

export default Button;
