import React from 'react';

import { Form } from 'formik';
import { Title, TextField, Button } from 'components';

import './style.scss';

const ForgotPasswordForm = ({ handleBlur, handleChange, isSubmitting, dirty, isValid, values }) => (
  <Form className="forgotPassword" noValidate>
    <Title className="c-title h-mb--2">Olvidaste tu contraseña?</Title>
    <div className="c-modal__description h-mb--2">Ingresa tu correo asociado a tu cuenta o número de celular.</div>
    <TextField
      id="username"
      inputType="text"
      key="username"
      name="username"
      required={true}
      value={values.username}
      onChange={handleChange}
      onBlur={handleBlur}
      label="Correo o celular"
    />
    <Button className="h-mt--2" buttonType="primary" type="submit" disabled={isSubmitting || !dirty || !isValid}>
      Enviar
    </Button>
  </Form>
);

export default ForgotPasswordForm;
