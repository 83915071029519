import React from 'react';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import CustomerProductDetails from '../CustomerProductDetails';

import './style.scss';

const CustomerProductForm = ({ remove, push, form }) => (
  <div className="data-products">
    <div className="title">Datos del producto</div>
    <TransitionGroup className="product-list">
      {!!form.values.products &&
        form.values.products.map((product, index) => (
          <CSSTransition key={index} timeout={500} classNames="item">
            <CustomerProductDetails index={index} product={product} remove={remove} {...form} />
          </CSSTransition>
        ))}
    </TransitionGroup>
    {!form.values.isReported && (
      <div className="add-product">
        <button
          type="button"
          onClick={() => push({ factoryDate: new Date(), buyDate: new Date(), warrantyDate: new Date() })}
        >
          <span>Agregar otro producto</span>
          <i className="icon-add"></i>
        </button>
      </div>
    )}
  </div>
);

export default CustomerProductForm;
