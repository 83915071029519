import React from 'react';
import { Form } from 'formik';

import { Button, Title, TextField, Feature } from 'components';

import './style.scss';

const LoginForm = ({
  dirty,
  isValid,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  isSubmitting,
  resetForm,
  triggerModalOpen
}) => {
  const resetAndTriggerModal = () => {
    resetForm();
    triggerModalOpen();
  };

  return (
    <Form className="login__form box" noValidate>
      <div className="center">
        <Title className="h-mb--2">Bienvenido</Title>
        <TextField
          id="username"
          name="username"
          required={true}
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Usuario"
          hasError={errors.username && touched.username}
          errorMessage={errors.username}
          className="h-mb--2"
        />
        <TextField
          id="password"
          inputType="password"
          key="password"
          name="password"
          required={true}
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Contraseña"
          hasError={errors.password && touched.password}
          errorMessage={errors.password}
          className="h-mb--2"
        />
        <Button className="h-mt--2" buttonType="primary" type="submit" disabled={isSubmitting || !dirty || !isValid}>
          Ingresar
        </Button>
        <Feature name="loginWithCognito">
          <Button className="h-mt--1 h-mb--1" type="button" buttonType="link" onClick={resetAndTriggerModal}>
            Recuperar Contraseña
          </Button>
        </Feature>
      </div>
    </Form>
  );
};

export default LoginForm;
