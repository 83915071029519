import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { NotFoundPage } from 'components';

import CustomerList from 'components/organisms/Customer/CustomerList';
import CustomerForm from 'components/organisms/Customer/CustomerForm';

const CustomersPageRoutes = () => {
  return (
    <Switch>
      <Route exact path="/customers" render={() => <CustomerList />} />
      <Route path="/customers/new" render={({ ...props }) => <CustomerForm {...props} />} />
      <Route
        exact
        path="/customers/:id/edit"
        render={({ match, ...props }) => <CustomerForm editMode={true} customerId={match.params.id} {...props} />}
      />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default CustomersPageRoutes;
