import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';

import Session from 'services/session';

import { toast } from 'react-toastify';

import LoginForm from './component';

const LoginFormContainer = props => {
  return <LoginForm {...props} />;
};

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .max(30, 'Usuario muy largo.')
    .required('Este campo es obligatorio.'),
  password: Yup.string()
    .max(30, 'Contraseña muy larga.')
    .required('Este campo es obligatorio.')
});

const initialValues = { username: '', password: '' };

const handleSubmit = async (values, { setSubmitting, props, resetForm }) => {
  try {
    const user = await Session.signIn(values.username, values.password);
    if (user.challengeName && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      const { triggerNewPasswordModalShow } = props;
      resetForm();
      triggerNewPasswordModalShow(user);
    } else {
      await Session.saveCurrentUser();
      setSubmitting(false);
      props.history.push('/');
    }
  } catch (e) {
    if (e.code && e.code === 'PasswordResetRequiredException') {
      const { triggerChangePasswordModalShow } = props;
      resetForm();
      triggerChangePasswordModalShow(values.username);
    } else {
      toast.error('Error al iniciar sesion, contacte al administrador.');
      values.password = '';
      document.querySelector('#password').focus();
    }
    console.error('Error:', e);
  }
};

export default withFormik({
  mapPropsToValues: () => initialValues,
  validationSchema: validationSchema,
  handleSubmit: handleSubmit
})(LoginFormContainer);
