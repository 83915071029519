import React from 'react';

import { Button, TextField, Radio } from 'components';

const StepFive = () => {
  return (
    <div className="container h-pv--2">
      <div className="step-orders step-orders--five">
        <div className="row">
          <div className="col-12">
            <p className="h-fz--14 h-fw--bold h-mb--1 h-color--gray-text h-text-align--center">Detalles del pago</p>
          </div>
          <div className="col-10 offset-1">
            <ul className="summary-pay">
              <li>
                <span>Mantenimiento</span>
                <span>S/ 100.00</span>
              </li>
              <li>
                <span>Taller</span>
                <span>S/ 100.00</span>
              </li>
              <li>
                <span>Tuerca</span>
                <span>S/1.50</span>
              </li>
              <li className="total">
                <span>TOTAL</span>
                <span>S/ 201.50</span>
              </li>
            </ul>
          </div>
          <div className="col-12">
            <div className="summary-date">
              <div className="row">
                <div className="col-6">
                  <div className="info">
                    <span className="label">Cliente</span>
                    <span className="date">Alexander Llanos Ruiz</span>
                  </div>
                </div>
                <div className="col-3">
                  <div className="info">
                    <span className="label">DNI</span>
                    <span className="date">72465783</span>
                  </div>
                </div>
                <div className="col-3">
                  <div className="info">
                    <span className="label">Nro. recibo</span>
                    <span className="date">12931283</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="summary-email">
              <div className="row">
                <div className="col-6">
                  <TextField placeholder="Correo electronico" />
                </div>
                <div className="col-3">
                  <div className="c-btn--icon">
                    <i className="icon-send"></i>
                    <span>Enviar</span>
                  </div>
                </div>
                <div className="col-3">
                  <div className="c-btn--icon">
                    <i className="icon-download"></i>
                    <span>Descargar</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="summary-typepay">
              <div className="row">
                <div className="col-6">
                  <div className="c-container">
                    <label className="h-label">Tipo de pago</label>
                    <div className="c-components">
                      <Radio label="Efectivo" id="radio-1" />
                      <Radio label="Deposito" id="radio-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 offset-3 h-mt--3">
            <Button type="button" buttonType="primary">
              Pagar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepFive;
