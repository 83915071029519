// eslint-disable-next-line
import React from 'react';
import PropTypes from 'prop-types';

import { featureFlags } from 'config';

const Feature = ({ name, children }) => {
    const feature = featureFlags.find(feature => feature.name === name);

    if (feature && feature.active) {
        return children
    }
    return null
}

Feature.propTypes = {
    name: PropTypes.string,
    children: PropTypes.node.isRequired,
}

Feature.defaultProps = {
    name: ''
}

export default Feature;