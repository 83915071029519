import React from 'react';

import { navigationBarShow, navigationBarHide } from 'store/actions';
import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

import Session from 'services/session';
import Auth from '@aws-amplify/auth';

import Header from './component';

const HeaderContainer = () => {
  const navigationBar = useSelector(state => state.dashboard.navigationBar);
  const dispatch = useDispatch();
  const history = useHistory();

  const isAuthenticated = Session.isAuthenticated();
  const fullName = Session.getFullName();
  const toggleNavigation = () => {
    navigationBar ? dispatch(navigationBarHide()) : dispatch(navigationBarShow());
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      Session.signOut();
      history.push('/login');
    } catch (e) {
      console.log(e);
    }
  };

  return <Header {...{ toggleNavigation, signOut, isAuthenticated, fullName }} />;
};

export default HeaderContainer;
