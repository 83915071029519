import React from 'react';

import { Modal } from 'components';

import './style.scss';

const TechnicianImportModal = ({ fileInputRef, openFileInput, props }) => (
  <Modal title="Import" name="importTechnician" closeable>
    <div className="c-template">
      <div className="download h-mb--2">
        <span className="download--icon">
          <i className="icon-download"></i>
        </span>
        <a href="/technicians-template.csv" target="_blank" className="download--txt">
          <p>Descargar Plantilla</p>
        </a>
      </div>
      <input
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        className={'file-input'}
        ref={fileInputRef}
        onChange={event => console.log(event.target.files)}
      />
      <button className="upload" onClick={openFileInput}>
        <span className="upload--icon">
          <i className="icon-upload"></i>
        </span>
        <div className="upload--txt">
          <p>Subir Plantilla</p>
        </div>
      </button>
      {/* TODO: Delete btn */}
      {/* <Button className="h-mt--2" buttonType="primary" onClick={() => this.confirm()}>
            Subir plantilla
          </Button> */}
    </div>
  </Modal>
);

export default TechnicianImportModal;
