import React, { useState } from 'react';

import CustomerFilters from './component';

import { useSelector, useDispatch } from 'react-redux';
import { addCustomerFilters, cleanCustomerFilters, changeCustomerSearchCriteria, modalShow } from 'store/actions';

const selectWarranty = [
  { text: 'Vigente', value: 'onWarranty' },
  { text: 'Vencido', value: 'expired' }
];

const CustomerFiltersContainer = () => {
  const dispatch = useDispatch();
  const customerSearchCriteria = useSelector(state => state.dashboard.customers.searchCriteria);
  const customerFilters = useSelector(state => state.dashboard.customers.filters);
  const customerFiltersCount = useSelector(state => state.dashboard.customers.filtersApplied);

  const triggerAddCustomerFilters = payload => dispatch(addCustomerFilters(payload));
  const triggerCleanCustomerFilters = payload => dispatch(cleanCustomerFilters(payload));
  const triggerChangeCustomerSearchCriteria = payload => dispatch(changeCustomerSearchCriteria(payload));
  const triggerModalOpen = () => dispatch(modalShow('importCustomer'));

  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = () => setIsOpen(!isOpen);

  const cleanAndCloseFilters = () => {
    triggerCleanCustomerFilters();
    toggleIsOpen();
  };

  return (
    <CustomerFilters
      {...{
        triggerAddCustomerFilters,
        triggerChangeCustomerSearchCriteria,
        triggerModalOpen,
        customerFilters,
        customerFiltersCount,
        customerSearchCriteria,
        isOpen,
        toggleIsOpen,
        cleanAndCloseFilters,
        selectWarranty
      }}
    />
  );
};

export default CustomerFiltersContainer;
