import React, { useEffect, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { fromEntities, fromResource } from 'store/selectors';
import { resourceListReadRequest, resourceConcatListReadRequest } from 'store/actions';

import { Link } from 'react-router-dom';

import { Checkbox, WarrantyCell } from 'components';
import CustomerList from './component';

import { CUSTOMER_CATEGORIES } from 'services/mockData';

import { format } from 'date-fns';

const CustomerListContainer = () => {
  const dispatch = useDispatch();

  const customerList = useSelector(state =>
    fromEntities.getList(state, 'customers', fromResource.getList(state, 'customers'))
  );

  // const nextPage = useSelector(state => state.dashboard.customers.nextPage);
  const { canFetchMoreResults, rememberSelection, nextPage } = useSelector(state =>
    fromResource.getResourceState(state, 'customers')
  );

  const customerSearchCriteria = useSelector(state => state.dashboard.customers.searchCriteria);
  const customerFilters = useSelector(state => state.dashboard.customers.filters);

  const columns = React.useMemo(
    () => [
      {
        id: 'selection',
        Cell: ({ row }) => <Checkbox name={`${row.original._id}-selected`} {...row.getToggleRowSelectedProps()} />
      },
      {
        Header: 'Tipo',
        Cell: ({ row }) => {
          const currentCategory = CUSTOMER_CATEGORIES.find(element => element.value === row.original.category);
          return `${currentCategory.text}`;
        }
      },
      {
        Header: 'Nombre',
        Cell: ({ row }) => `${row.original.firstName} ${row.original.lastName}`
      },
      {
        Header: 'Artefacto',
        Cell: ({ row }) => {
          const productId = row.original.relevantWarrantyDetails.product;
          if (productId) {
            const relevantArtifact = row.original.products.find(
              product => product._id === row.original.relevantWarrantyDetails.product
            );
            return `${relevantArtifact.artifact} - ${relevantArtifact.brand}`;
          } else {
            return `-`;
          }
        }
      },
      {
        id: 'warranty',
        Header: 'Garantía',
        Cell: ({ row }) => {
          const productId = row.original.relevantWarrantyDetails.product;
          return (
            <WarrantyCell
              onWarranty={row.original.relevantWarrantyDetails.onWarranty}
              warrantyDate={
                productId ? format(new Date(row.original.relevantWarrantyDetails.warrantyDate), 'dd/MM/yyyy') : '-'
              }
            />
          );
        }
      },
      {
        Header: 'Celular',
        accessor: 'phone'
      },
      {
        Header: 'Dirección',
        accessor: 'fullAddress'
      },
      {
        id: 'editButton',
        Cell: ({ row }) => (
          <Link className="c-link" to={{ pathname: `/customers/${row.original._id}/edit` }}>
            Ver detalles
          </Link>
        )
      }
    ],
    []
  );

  const memoizedFetchCustomers = useCallback(() => {
    const filters = { ...customerFilters, q: customerSearchCriteria };

    const clean = require('obj-clean');
    dispatch(resourceListReadRequest('customers', clean(filters)));
  }, [dispatch, customerFilters, customerSearchCriteria]);

  useEffect(() => {
    memoizedFetchCustomers();
  }, [memoizedFetchCustomers]);

  const memoziedFetchConcatCustomers = useCallback(() => {
    if (canFetchMoreResults) {
      const filters = { ...customerFilters, page: nextPage, q: customerSearchCriteria };

      const clean = require('obj-clean');
      dispatch(resourceConcatListReadRequest('customers', clean(filters)));
    }
  }, [dispatch, customerFilters, customerSearchCriteria, canFetchMoreResults, nextPage]);

  const fetchMoreData = () => memoziedFetchConcatCustomers();

  return (
    <CustomerList
      {...{
        fetchMoreData,
        columns,
        customerList,
        rememberSelection
      }}
    />
  );
};

export default CustomerListContainer;
