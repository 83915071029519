import React from 'react';

const OrderSummary = ({
  orderSelectedCustomerFullName,
  orderSelectedCustomerProduct,
  orderSelectedTechnicianFullName,
  orderPrice
}) => (
  <div className="step-orders--summary">
    <div className="row">
      <div className="col-3">
        <div className="info">
          <span className="label">Cliente</span>
          <span className="date">{orderSelectedCustomerFullName}</span>
        </div>
      </div>
      <div className="col-3">
        <div className="info">
          <span className="label">Producto</span>
          <span className="date">{orderSelectedCustomerProduct}</span>
        </div>
      </div>
      <div className="col-3">
        <div className="info">
          <span className="label">Técnico</span>
          <span className="date">{orderSelectedTechnicianFullName}</span>
        </div>
      </div>
      <div className="col-3">
        <div className="info h-text-align--right">
          <span className="label">Precio final</span>
          <span className="date">
            <b>{orderPrice}</b>
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default OrderSummary;
