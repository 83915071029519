import React from 'react';
import { NavLink } from 'react-router-dom';

import { Icon } from 'components';

import './style.scss';

const NavigationElement = ({ headerLink, header, icon }) => (
  <NavLink to={headerLink} className="nav__menu--elements" activeClassName="active">
    <Icon icon={icon} />
    <span>{header}</span>
  </NavLink>
);

export default NavigationElement;
