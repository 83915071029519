/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ReactDatePicker, { registerLocale, setDefaultLocale, getDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import './style.scss';

if (getDefaultLocale() !== 'es') {
  registerLocale('es', es);

  setDefaultLocale('es');
}

const Datepicker = ({
  disabled,
  name,
  label,
  value,
  onChange,
  hasError,
  errorMessage,
  placeholder,
  className,
  minDate,
  maxDate,
  pickerType,
  ...attributes
}) => {
  const ref = React.createRef();

  const CustomInput = forwardRef(({ onClick, value: _value, onChange: _onChange, disabled }, _ref) => (
    <div className={classNames('c-datepicker', { error: hasError }, className)}>
      <label>{label}</label>
      <div className="day-picker-input">
        <input
          onClick={onClick}
          placeholder={placeholder}
          value={_value}
          onChange={_onChange}
          ref={_ref}
          disabled={disabled}
        />
      </div>
      {pickerType === 'date' && <i className="icon-calendar" />}
      {pickerType === 'time' && <i className="icon-time" />}
      {hasError && <span className="label-error">{errorMessage}</span>}
    </div>
  ));

  CustomInput.displayName = 'CustomInput';
  const sanitizedValue = value instanceof Date ? value : value && value.length ? parseISO(value) : new Date();
  return (
    <ReactDatePicker
      dateFormat="dd/MM/yyyy"
      selected={sanitizedValue}
      onChange={onChange}
      customInput={<CustomInput ref={ref} />}
      minDate={minDate}
      maxDate={maxDate}
      disabled={disabled}
      {...attributes}
    />
  );
};

Datepicker.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]).isRequired,
  placeholder: PropTypes.string,
  pickerType: PropTypes.string,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
};

Datepicker.defaultProps = {
  attributes: [],
  className: '',
  label: '',
  disabled: false,
  hasError: false,
  errorMessage: '',
  placeholder: 'dd/mm/aaaa',
  pickerType: 'date',
  minDate: '',
  maxDate: ''
};

export default Datepicker;
