import React from 'react';

import { getIn } from 'formik';
import { TextField, Select, Datepicker } from 'components';

import { PRODUCTS_MOCK_DATA } from 'services/mockData';

const getTextAndValuesOnly = array => array.map(({ text, value }) => ({ text, value }));
const brands = getTextAndValuesOnly(PRODUCTS_MOCK_DATA);
const artifacts = getTextAndValuesOnly(PRODUCTS_MOCK_DATA[0].artifacts);

const CustomerProductDetails = ({
  onlyProduct,
  index,
  remove,
  product,
  handleChange,
  touched,
  errors,
  values,
  setFieldValue
}) => (
  <div key={index} className="data-product new-item">
    <div className="data-product--header">
      <span>Producto {!onlyProduct && index + 1}</span>
      {!onlyProduct && !values.isReported && <i className="icon-trash" onClick={() => remove(index)}></i>}
    </div>
    <div className="data-product--body">
      <div className="form">
        <Select
          name={`products.${index}.brand`}
          label="Marca"
          placeholder="Seleccione"
          required={true}
          selectedOption={product.brand}
          onChange={handleChange}
          hasError={Array.isArray(touched.products) && getIn(errors, `products.${index}.brand`)}
          errorMessage={getIn(errors, `products.${index}.brand`)}
          options={brands}
          disabled={!!values.isReported}
        />
        <Select
          name={`products.${index}.artifact`}
          label="Artefacto"
          placeholder="Seleccione"
          required={true}
          selectedOption={product.artifact}
          onChange={handleChange}
          hasError={Array.isArray(touched.products) && getIn(errors, `products.${index}.artifact`)}
          errorMessage={getIn(errors, `products.${index}.artifact`)}
          options={artifacts}
          disabled={!!values.isReported || !product.brand}
        />
        <Select
          name={`products.${index}.model`}
          label="Modelo"
          placeholder="Seleccione"
          required={true}
          selectedOption={product.model}
          onChange={handleChange}
          hasError={Array.isArray(touched.products) && getIn(errors, `products.${index}.model`)}
          errorMessage={getIn(errors, `products.${index}.model`)}
          options={brands}
          disabled={!!values.isReported || !product.artifact}
        />
      </div>
      <div className="form">
        <TextField
          id={`products.${index}.serie`}
          name={`products.${index}.serie`}
          label="Serie"
          required={true}
          onChange={handleChange}
          value={product.serie || ''}
          hasError={Array.isArray(touched.products) && getIn(errors, `products.${index}.serie`)}
          errorMessage={getIn(errors, `products.${index}.serie`)}
          disabled={!!values.isReported}
        />
        <TextField
          id={`products.${index}.code`}
          name={`products.${index}.code`}
          label="Codigo"
          required={true}
          onChange={handleChange}
          value={product.code || ''}
          hasError={Array.isArray(touched.products) && getIn(errors, `products.${index}.serie`)}
          errorMessage={getIn(errors, `products.${index}.serie`)}
          disabled={!!values.isReported}
        />
        <Select
          name={`products.${index}.distributor`}
          label="Distribuidor"
          placeholder="Seleccione"
          required={true}
          selectedOption={product.distributor}
          onChange={handleChange}
          hasError={Array.isArray(touched.products) && getIn(errors, `products.${index}.distributor`)}
          errorMessage={getIn(errors, `products.${index}.distributor`)}
          options={brands}
          disabled={!!values.isReported}
        />
      </div>
      <div className="form">
        <Datepicker
          name={`products.${index}.buyDate`}
          label="Fecha de compra:"
          placeholder="dd/mm/aaaa"
          value={product.buyDate}
          onChange={e => setFieldValue(`products.${index}.buyDate`, e)}
          disabled={!!values.isReported}
        />
        <Datepicker
          name={`products.${index}.factoryDate`}
          label="Fecha de fabricación:"
          placeholder="dd/mm/aaaa"
          value={product.factoryDate}
          onChange={e => setFieldValue(`products.${index}.factoryDate`, e)}
          disabled={!!values.isReported}
        />
        <Datepicker
          name={`products.${index}.warrantyDate`}
          label="Fecha de vencimiento garantía:"
          placeholder="dd/mm/aaaa"
          value={product.warrantyDate}
          onChange={e => setFieldValue(`products.${index}.warrantyDate`, e)}
          disabled={!!values.isReported}
        />
      </div>
    </div>
  </div>
);

export default CustomerProductDetails;
