import React from 'react';
import PropTypes from 'prop-types';

import { Title, Button, Table, Box } from 'components';

import CustomerImportModal from '../CustomerImportModal';
import CustomerFilters from '../CustomerFilters';

import { Link } from 'react-router-dom';

const CustomerList = ({ columns, customerList, fetchMoreData, rememberSelection }) => (
  <Box
    title={<Title>Listado de clientes</Title>}
    customActions={
      <Link to={{ pathname: '/customers/new' }}>
        <Button type="button" buttonType="primary">
          Agregar
        </Button>
      </Link>
    }
  >
    <CustomerFilters />
    <Table fetchMoreData={fetchMoreData} columns={columns} data={customerList} rememberSelection={rememberSelection} />
    <CustomerImportModal />
  </Box>
);

CustomerList.propTypes = {
  customerList: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default CustomerList;
