import React, { useEffect, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { fromEntities, fromResource } from 'store/selectors';
import { resourceListReadRequest } from 'store/actions';

import OrderList from './component';

const OrderListContainer = props => {
  const dispatch = useDispatch();

  const orderList = useSelector(state => fromEntities.getList(state, 'orders', fromResource.getList(state, 'orders')));

  const memoizedFetchOrders = useCallback(() => {
    dispatch(resourceListReadRequest('orders'));
  }, [dispatch]);

  useEffect(() => {
    memoizedFetchOrders();
  }, [memoizedFetchOrders]);

  return <OrderList {...{ orderList }} {...props} />;
};

export default OrderListContainer;
