import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Input = ({ id, name, inputType, value, onChange, onBlur, disabled, required, attributes }) => (
  <input
    id={id}
    name={name}
    type={inputType}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    required={required}
    {...attributes}
    autoComplete="off"
    disabled={disabled}
  />
);

Input.propTypes = {
  disabled: PropTypes.bool,
  inputType: PropTypes.oneOf(['text', 'number', 'password']),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

Input.defaultTypes = {
  disabled: false,
  inputType: 'text'
};

export default Input;
