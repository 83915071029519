import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';

import { Icon } from 'components';

import './style.scss';

function DropdownMenu({ heading, items, withIcon }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const dropdownMenu = React.useRef(null);

  const toggleIsModalOpen = event => {
    event.preventDefault();
    isModalOpen ? memoizedCloseMenu(event) : showMenu();
  };
  const showMenu = () => setModalOpen(true);

  const memoizedCloseMenu = useCallback(
    event => {
      if (dropdownMenu.current && !dropdownMenu.current.contains(event.target)) {
        setModalOpen(false);
      }
    },
    [dropdownMenu]
  );

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('click', memoizedCloseMenu);
    } else {
      document.removeEventListener('click', memoizedCloseMenu);
    }
  }, [isModalOpen, memoizedCloseMenu]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div tabIndex="0" role="button" aria-pressed="false" className="c-dropdown" onClick={toggleIsModalOpen}>
      {heading}
      {withIcon && <Icon icon="icon-chevron-down" />}

      <div className={className('c-dropdown__cont', { active: isModalOpen })} ref={dropdownMenu}>
        {items}
      </div>
    </div>
  );
}

DropdownMenu.propTypes = {
  heading: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(PropTypes.node).isRequired,
  withIcon: PropTypes.bool
};

DropdownMenu.defaultProps = {
  withIcon: true
};

export default DropdownMenu;
