import React from 'react';

import { Radio, Button, Datepicker, Search, Select, TextField } from 'components';

import { CUSTOMER_CATEGORIES, ORDER_SERVICES_TYPES } from 'services/mockData';
import OrderSummary from '../../Summary';

const StepThree = ({ orderTypeOfService, triggerChangeOrderValuePerPath }) => {
  return (
    <>
      <OrderSummary />
      <div className="container">
        <div className="step-orders step-orders--three">
          <div className="h-pt--3 h-mb--3 h-align-items--center">
            <div className="row">
              <div className="col-10">
                <p className="h-fz--14 h-fw--bold h-mb--2 h-color--gray-text">Datos de la revisión</p>
              </div>
              <div className="col-2">
                <div className="c-btn--icon">
                  <i className="icon-save"></i>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4 h-mb--2">
                <Select label="Técnico" placeholder="Seleccione" options={CUSTOMER_CATEGORIES} />
              </div>
              <div className="col-4 h-mb--2">
                <Select
                  label="Tipo de servicio"
                  placeholder="Seleccione"
                  value={orderTypeOfService}
                  onChange={e =>
                    triggerChangeOrderValuePerPath({ key: 'order.schedule.typeOfService', value: e.target.value })
                  }
                  options={ORDER_SERVICES_TYPES}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-8 h-mb--2">
                <TextField placeholder="Detalle de revision" />
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <Datepicker label="Fecha de programación" placeholder="dd/mm/aaaa" value={new Date()} />
              </div>
              <div className="col-4">
                <div className="c-container">
                  <label className="h-label">Taller</label>
                  <div className="c-components">
                    <Radio label="Si" id="radio-1" />
                    <Radio label="No" id="radio-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-pt--3 h-mb--3 h-align-items--center h-border--top">
          <div className="row">
            <div className="col-12">
              <p className="h-fz--14 h-fw--bold h-mb--2 h-color--gray-text">Detalle de la reparación</p>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <TextField placeholder="Precio reparación" />
            </div>
          </div>
        </div>
        <div className="h-pt--3 h-align-items--center h-border--top">
          <div className="row">
            <div className="col-12">
              <p className="h-fz--14 h-fw--bold h-mb--2 h-color--gray-text">Piezas</p>
            </div>
          </div>
          <div className="row">
            <div className="col-8">
              <Search placeholder="Buscar piezas" />
            </div>
            <div className="col-3">
              <Button className="c-btn--no-block" type="button" buttonType="secondary">
                Agregar
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="c-table-piece h-mt--2 h-mb--2">
                <div className="c-table-piece--header">
                  <span className="item">Nombre</span>
                  <span className="item h-text-align--right h-mr--10">Precio compra</span>
                  <span className="item h-text-align--right">Precio venta</span>
                  <div className="item-trash"></div>
                </div>
                <div className="c-table-piece--body">
                  <div className="list">
                    <span className="item">Tuerca</span>
                    <span className="item h-text-align--right h-mr--10">S/1.00</span>
                    <div className="item-input">
                      <TextField />
                    </div>
                    <div className="item-trash">
                      <span className="icon-trash"></span>
                    </div>
                  </div>
                  <div className="list">
                    <span className="item">Tuerca</span>
                    <span className="item h-text-align--right h-mr--10">S/1.00</span>
                    <div className="item-input">
                      <TextField />
                    </div>
                    <div className="item-trash">
                      <span className="icon-trash"></span>
                    </div>
                  </div>
                </div>
                <div className="c-table-piece--footer">
                  <span className="item">TOTAL PIEZAS</span>
                  <span className="item h-text-align--right">S/0.00</span>
                  <div className="item-trash"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepThree;
