import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Box = ({ children, customActions, title }) => (
  <div className="box-body">
    <div className="box-body__title">
      {title}
      {customActions}
    </div>
    {children}
  </div>
);

Box.propTypes = {
  children: PropTypes.node.isRequired,
  customActions: PropTypes.node,
  title: PropTypes.node
};

export default Box;
