import React, { useCallback, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { fromEntities, fromResource } from 'store/selectors';

import { changeOrderValuePerPath } from 'store/actions';
import { resourceSelectListReadRequest } from 'store/actions';

import StepTwo from './component';

const StepTwoContainer = ({ nextStep }) => {
  const dispatch = useDispatch();

  const orderTechnicianList = useSelector(state =>
    fromEntities.getList(state, 'selectTechnicians', fromResource.getList(state, 'selectTechnicians'))
  );

  const orderCreationDate = useSelector(state => state.dashboard.order.creationDate);
  const orderTypeOfService = useSelector(state => state.dashboard.order.schedule.typeOfService);
  const orderTechnician = useSelector(state => state.dashboard.order.schedule.technician);
  const orderReasonOfService = useSelector(state => state.dashboard.order.schedule.reasonOfService);
  const orderHour = useSelector(state => state.dashboard.order.schedule.hour);
  const orderScheduleDate = useSelector(state => state.dashboard.order.schedule.date);

  const triggerChangeOrderValuePerPath = payload => dispatch(changeOrderValuePerPath(payload));
  const triggerNextStep = () => true && nextStep();

  const fetchSelectTechnicians = useCallback(() => {
    dispatch(resourceSelectListReadRequest('technicians'));
  }, [dispatch]);

  useEffect(() => {
    fetchSelectTechnicians();
  }, [fetchSelectTechnicians]);

  return (
    <StepTwo
      {...{
        triggerNextStep,
        orderTechnicianList,
        orderCreationDate,
        orderTypeOfService,
        orderReasonOfService,
        orderTechnician,
        orderScheduleDate,
        orderHour,
        triggerChangeOrderValuePerPath
      }}
    />
  );
};

export default StepTwoContainer;
