import { TECHNICIANS } from 'services/mockData';

export const initialState = {
  navigationBar: true,
  customers: {
    searchCriteria: '',
    filters: {
      customerType: '',
      warrantyType: '',
      fromDate: '',
      toDate: '',
      showReported: false
    },
    filtersApplied: 0
  },
  orders: {
    searchCriteria: ''
  },
  order: {
    orderNumber: '',
    customerSearchCriteria: '',
    isCustomerFormVisible: false,
    isProductFormVisible: false,
    selectedOrderCustomer: {},
    selectedOrderCustomerProductList: [],
    selectedOrderCustomerProduct: '',
    creationDate: new Date(),
    schedule: {
      typeOfService: '',
      reasonOfService: '',
      date: new Date(),
      hour: '',
      technician: '',
      price: ''
    },
    baseFee: 100
  }
};

export const isVisible = (state = initialState, name) => !!state[name];

export const getSelectedCustomerProduct = (state = initialState) => {
  const product = state.order.selectedOrderCustomerProductList.find(
    element => element.value === state.order.selectedOrderCustomerProduct
  );
  return product ? product.text : '-';
};

export const getSelectedOrderTechnician = (state = initialState) => {
  const technician = TECHNICIANS.find(element => element.value === state.order.schedule.technician);
  return technician ? technician.text : '-';
};
