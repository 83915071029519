import React from 'react';

import { Formik, Form } from 'formik';

import { Button, Search, DropdownMenu, ClickableMenuItem, Select } from 'components';
import CustomerDetails from 'components/organisms/Customer/CustomerDetails';
import CustomerProductDetails from 'components/organisms/Customer/CustomerProductDetails';

const StepOne = ({
  triggerNextStep,
  orderCustomerSearchCriteria,
  orderIsCustomerFormVisible,
  orderIsProductFormVisible,
  triggerChangeOrderCustomerSearchCriteria,
  triggerShowOrderCustomerForm,
  triggerShowOrderProductForm,
  triggerSelectOrderCustomer,
  triggerSelectOrderCustomerProduct,
  triggerCleanSelectedOrderCustomer,
  selectedOrderCustomer,
  selectedOrderCustomerProduct,
  selectedOrderCustomerProductList,
  customerList,
  highlightFullName,
  customerInitialValues,
  productInitialValues,
  customerValidationSchema,
  triggerCreateCustomer
}) => (
  <>
    <div className="container">
      <div className="step-orders step-orders--one">
        <div className="h-pt--3 h-mb--3 h-align-items--center">
          {!orderIsCustomerFormVisible && (
            <div className="row">
              <div className="col-12">
                <p className="h-fz--14 h-fw--bold h-mb--2 h-color--gray-text">Cliente</p>
              </div>
              <div className="col">
                {!Object.entries(selectedOrderCustomer).length && (
                  <DropdownMenu
                    withIcon={false}
                    heading={
                      <Search
                        placeholder="Buscar cliente"
                        value={orderCustomerSearchCriteria}
                        onChange={e => triggerChangeOrderCustomerSearchCriteria({ searchCriteria: e.target.value })}
                      />
                    }
                    items={customerList.map(customer => (
                      <ClickableMenuItem
                        key={`customer-${customer._id}`}
                        onClick={() => triggerSelectOrderCustomer(customer)}
                      >
                        <span
                          className="name"
                          dangerouslySetInnerHTML={{
                            __html: highlightFullName(customer.fullName, orderCustomerSearchCriteria)
                          }}
                        />
                        <span className="document-number">{customer.documentNumber}</span>
                      </ClickableMenuItem>
                    ))}
                  />
                )}
                {!!Object.entries(selectedOrderCustomer).length && (
                  <Search disabled={true} placeholder="Buscar cliente" value={selectedOrderCustomer.fullName} />
                )}
              </div>

              <div className="col-3">
                <div className="search-btn">
                  {!Object.entries(selectedOrderCustomer).length && (
                    <Button
                      className="h-mr--1"
                      type="button"
                      buttonType="secondary"
                      onClick={triggerShowOrderCustomerForm}
                    >
                      Nuevo
                    </Button>
                  )}

                  {!!Object.entries(selectedOrderCustomer).length && (
                    <Button
                      className="h-mr--1"
                      type="button"
                      buttonType="secondary"
                      onClick={triggerCleanSelectedOrderCustomer}
                    >
                      Borrar
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}
          {orderIsCustomerFormVisible && (
            <div className="row">
              <div className="col-12">
                <Formik
                  enableReinitialize
                  initialValues={customerInitialValues}
                  onSubmit={(values, { setSubmitting }) => {
                    triggerCreateCustomer({ customer: values });
                  }}
                  validationSchema={customerValidationSchema}
                >
                  {({ ...props }) => (
                    <Form noValidate>
                      <CustomerDetails {...props} onlyCustomer={true} />
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          )}
        </div>
        <div className="h-pt--3 h-mb--3 h-align-items--center h-border--top">
          {!orderIsProductFormVisible && (
            <div className="row">
              <div className="col-12">
                <p className="h-fz--14 h-fw--bold h-mb--2 h-color--gray-text">Producto</p>
              </div>
              <div className="col">
                <Select
                  label="Tipo de servicio"
                  placeholder="Seleccione"
                  name="orderSelectedProduct"
                  options={selectedOrderCustomerProductList}
                  value={selectedOrderCustomerProduct}
                  onChange={event => triggerSelectOrderCustomerProduct(event.target.value)}
                  disabled={!selectedOrderCustomerProductList.length}
                />
              </div>
              <div className="col-3">
                <div className="search-btn">
                  <Button
                    className="h-mr--1"
                    type="button"
                    buttonType="secondary"
                    onClick={triggerShowOrderProductForm}
                    disabled={!Object.entries(selectedOrderCustomer).length}
                  >
                    Nuevo
                  </Button>
                </div>
              </div>
            </div>
          )}
          {orderIsProductFormVisible && (
            <div className="row">
              <div className="col-12">
                <Formik
                  enableReinitialize
                  initialValues={customerInitialValues}
                  onSubmit={(values, { setSubmitting }) => {
                    triggerCreateCustomer({ customer: values });
                  }}
                  // validationSchema={validationSchema}
                >
                  {({ ...props }) => (
                    <div className="data-products" style={{ padding: 0 }}>
                      <div className="title">Datos del producto</div>
                      <Form noValidate>
                        <CustomerProductDetails {...props} product={productInitialValues} onlyProduct={true} />
                        {/* <ProductDetail {...props} product={initialValuesProduct} onlyProduct={true} /> */}
                      </Form>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
    <div className="container footer-ordes">
      <div className="row">
        <div className="col-5">
          <Button
            type="button"
            buttonType="secondary"
            // disabled={!selectedCustomer && !isCustomerFormVisible && !selectedProduct}
            // onClick={triggerCleanSelections}
          >
            Cancelar
          </Button>
        </div>
        <div className="col-5">
          <Button
            type="button"
            buttonType="primary"
            disabled={!Object.entries(selectedOrderCustomer).length || !selectedOrderCustomerProduct}
            // onClick={createNewOrder}
            onClick={triggerNextStep}
          >
            Siguiente
          </Button>
        </div>
      </div>
    </div>
  </>
);

export default StepOne;
