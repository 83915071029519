/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import './style.scss';

const Select = ({
  name,
  label,
  selectedOption,
  onChange,
  placeholder,
  options,
  className,
  hasError,
  errorMessage,
  valueKey,
  ...attributes
}) => (
  <div className={classNames('c-select', { error: hasError }, className)}>
    <label>
      <span className="placeholder">{label}</span>
      <select name={name} value={selectedOption} onChange={onChange} className="select" {...attributes}>
        <option value="">{placeholder}</option>
        {options.map(option => (
          <option key={`option-${name}-${option[valueKey]}`} value={option[valueKey]}>
            {option.text}
          </option>
        ))}
      </select>
      <i className="icon-chevron-down" />
    </label>
    {hasError && <span className="label-error">{errorMessage}</span>}
  </div>
);

Select.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedOption: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

Select.defaultProps = {
  valueKey: 'value',
  attributes: [],
  className: '',
  hasError: false,
  errorMessage: '',
  selectedOption: '',
  placeholder: ''
};

export default Select;
