import * as React from 'react';

import { Dot } from 'components';

import './style.scss';

const WarrantyCell = ({ onWarranty, warrantyDate }) => (
  <div className="warranty-cell">
    <div className="warranty-cell__details">
      <Dot type={`${onWarranty ? 'success' : 'error'}`} />
      <div className="text">{onWarranty ? 'Vigente' : 'Vencido'}</div>
    </div>
    <div className="warranty-cell__date">{warrantyDate !== 'Invalid Date' ? warrantyDate : ''}</div>
  </div>
);

export default WarrantyCell;
