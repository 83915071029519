import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const Title = ({ children, className, ...attributes }) => (
  <div className={classNames('c-title', className)} {...attributes}>
    {children}
  </div>
);

Title.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string
};

Title.defaultProps = {
  className: ''
};

export default Title;
