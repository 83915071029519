import React from 'react';

import ForgotPasswordModal from 'components/organisms/ForgotPasswordForm/container';
import ChangePasswordModal from 'components/organisms/ChangePasswordForm/container';
import NewPasswordModal from 'components/organisms/NewPasswordForm/container';
import LoginForm from 'components/organisms/LoginForm';

import reysepLogo from 'images/logo-white.svg';

import './style.scss';

const LoginPage = ({ ...props }) => {
  return (
    <div className="login">
      <div className="login__background left">
        <div className="login__logo">
          <img alt="Reysep" src={reysepLogo} />
        </div>
        <div className="login__txt">
          {/* <h2>Lorem ipsum dolor</h2>
          <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</p> */}
        </div>
      </div>
      <LoginForm {...props} />
      <div className="login__background right"></div>
      <ForgotPasswordModal />
      <ChangePasswordModal />
      <NewPasswordModal />
    </div>
  );
};

export default LoginPage;
