export const NAVIGATION_BAR_SHOW = 'NAVIGATION_BAR_SHOW';
export const NAVIGATION_BAR_HIDE = 'NAVIGATION_BAR_HIDE';

export const CUSTOMERS_CHANGE_FILTER = 'CUSTOMERS_CHANGE_FILTER';
export const CUSTOMERS_CLEAN_FILTER = 'CUSTOMERS_CLEAN_FILTER';
export const CUSTOMERS_CHANGE_SEARCH_CRITERIA = 'CUSTOMERS_CHANGE_SEARCH_CRITERIA';
export const CUSTOMERS_GET_NEW_PAGE = 'CUSTOMERS_GET_NEW_PAGE';

export const ORDER_CHANGE_CUSTOMER_SEARCH_CRITERIA = 'ORDER_CHANGE_CUSTOMER_SEARCH_CRITERIA';
export const ORDER_SHOW_CUSTOMER_FORM = 'ORDER_SHOW_CUSTOMER_FORM';
export const ORDER_SHOW_PRODUCT_FORM = 'ORDER_SHOW_PRODUCT_FORM';
export const ORDER_SELECT_CURRENT = 'ORDER_SELECT_CURRENT';
export const ORDER_SELECT_CUSTOMER = 'ORDER_SELECT_CUSTOMER';
export const ORDER_SELECT_CUSTOMER_PRODUCT = 'ORDER_SELECT_CUSTOMER_PRODUCT';
export const ORDER_CLEAN_SELECTED_ORDER_CUSTOMER = 'ORDER_CLEAN_SELECTED_ORDER_CUSTOMER';
export const ORDER_CHANGE_VALUE_PER_PATH = 'ORDER_CHANGE_VALUE_PER_PATH';

export const navigationBarShow = () => ({
  type: NAVIGATION_BAR_SHOW
});

export const navigationBarHide = () => ({
  type: NAVIGATION_BAR_HIDE
});

export const addCustomerFilters = payload => ({ type: CUSTOMERS_CHANGE_FILTER, payload });

export const cleanCustomerFilters = () => ({ type: CUSTOMERS_CLEAN_FILTER });

export const changeCustomerSearchCriteria = payload => ({ type: CUSTOMERS_CHANGE_SEARCH_CRITERIA, payload });

export const changeOrderCustomerSearchCriteria = payload => ({ type: ORDER_CHANGE_CUSTOMER_SEARCH_CRITERIA, payload });

// Orders

export const showOrderCustomerForm = () => ({ type: ORDER_SHOW_CUSTOMER_FORM });

export const showOrderProductForm = () => ({ type: ORDER_SHOW_PRODUCT_FORM });

export const selectCurrentOrder = payload => ({ type: ORDER_SELECT_CURRENT, payload });

export const selectOrderCustomer = payload => ({ type: ORDER_SELECT_CUSTOMER, payload });

export const selectOrderCustomerProduct = payload => ({ type: ORDER_SELECT_CUSTOMER_PRODUCT, payload });

export const cleanSelectedOrderCustomer = () => ({ type: ORDER_CLEAN_SELECTED_ORDER_CUSTOMER });

export const changeOrderValuePerPath = payload => ({ type: ORDER_CHANGE_VALUE_PER_PATH, payload });
